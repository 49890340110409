import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { deleteTweet, fetchDeclinedTweets, setDeclinedTweets, setDeclinedTweetsLoadingState, setPageableExploreTweets, setPageableTweets, setRetweetCount, setTweetErrorMessage, setTweetLikeCount, setTweetSentState, setTweets, setTweetsLoadingState } from "./actionCreators";
import {
    AddPollActionInterface,
    AddQuoteTweetActionInterface,
    AddScheduledTweetActionInterface,
    AddTweetActionInterface,
    ChangeReplyTypeActionInterface,
    DeleteScheduledTweetsActionInterface,
    FetchBookmarksActionInterface,
    FetchDeleteTweetActionInterface,
    FetchFollowersTweetsActionInterface,
    FetchMediaTweetsActionInterface,
    FetchTweetsActionInterface,
    FetchTweetsByTagActionInterface,
    FetchTweetsByTextActionInterface,
    FetchTweetsWithQuotesByIdActionInterface,
    FetchTweetsWithVideoActionInterface,
    LikeTweetActionInterface,
    RetweetActionInterface,
    TweetsActionType,
    UpdateScheduledTweetActionInterface,
    VoteActionInterface,
    FetchLatestTweetsActionInterface,
    FetchTopTweetsActionInterface,
    FetchDeclinedTWeetsAcionInterface,
    SetDeclinedApplyAppealActionInterface
} from "./contracts/actionTypes";
import { TagApi } from "../../../services/api/tag-service/tagApi";
import { TweetResponse } from "../../../types/tweet";
import { deleteUserTweet } from "../userTweets/actionCreators";
import { LoadingStatus } from "../../../types/common";
import { PAGE_TOTAL_COUNT } from "../../../constants/common-constants";
import { BookmarkApi } from "../../../services/api/tweet-service/bookmarkApi";
import { LikeTweetApi } from "../../../services/api/tweet-service/likeTweetApi";
import { PollApi } from "../../../services/api/tweet-service/pollApi";
import { RetweetApi } from "../../../services/api/tweet-service/retweetApi";
import { ScheduledTweetApi } from "../../../services/api/tweet-service/scheduledTweetApi";
import { TweetApi } from "../../../services/api/tweet-service/tweetApi";
import { deleteListTweet } from "../list/actionCreators";
import { TweetSentState } from './contracts/state';
import { NotificationTweetResponse } from '../../../types/notification';
import { setOpenSnackBar } from "../actionSnackbar/actionCreators";

export function* fetchTweetsRequest({ payload }: FetchTweetsActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getTweets, payload);
        yield put(setPageableTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchMediaTweetsRequest({ payload }: FetchMediaTweetsActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getMediaTweets, payload);
        yield put(setPageableExploreTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchTweetsWithVideoRequest({ payload }: FetchTweetsWithVideoActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getTweetsWithVideo, payload);
        yield put(setPageableExploreTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchFollowersTweetsRequest({ payload }: FetchFollowersTweetsActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getFollowersTweets, payload);
        yield put(setPageableTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchTweetsByTagRequest({ payload }: FetchTweetsByTagActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TagApi.getTweetsByTag, payload);
        yield put(setPageableExploreTweets({
          items: response.data,
          pagesCount: 0
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchTweetsByTextRequest({ payload }: FetchTweetsByTextActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.searchTweets, payload.text, payload.pageNumber);
        yield put(setPageableExploreTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchQuotesByTweetIdRequest({ payload }: FetchTweetsWithQuotesByIdActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getQuotesByTweetId, payload.tweetId, payload.pageNumber);
        yield put(setPageableTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* addTweetRequest({ payload }: AddTweetActionInterface) {
    try {
        yield put(setTweetSentState(TweetSentState.IDEL));
        const response: AxiosResponse<TweetResponse> = yield call(TweetApi.createTweet, payload);
        if (response.data) {
            if ((response.data.data || response?.data?.message) && !response.data?.status) {
                yield put(setTweetErrorMessage(response?.data?.message || 'Something went wrong!!'));
                yield put(setTweetsLoadingState(LoadingStatus.ERROR));
                yield put(setTweetSentState(TweetSentState.ERROR));
            } else {
                yield put(setTweetSentState(TweetSentState.SENT));
            }
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
        yield put(setTweetSentState(TweetSentState.ERROR));
    }
}

export function* addPollRequest({ payload }: AddPollActionInterface) {
    try {
        yield put(setTweetSentState(TweetSentState.IDEL));
        const response: AxiosResponse<TweetResponse> = yield call(PollApi.createPoll, payload);
        if (response.data) {
            yield put(setTweetSentState(TweetSentState.SENT));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
        yield put(setTweetSentState(TweetSentState.ERROR));
    }
}

export function* addScheduledTweetRequest({ payload }: AddScheduledTweetActionInterface) {
    try {
        yield put(setTweetSentState(TweetSentState.IDEL));
        const response: AxiosResponse<TweetResponse> = yield call(ScheduledTweetApi.createScheduledTweet, payload);
        if (response.data) {
            yield put(setTweetSentState(TweetSentState.SENT));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
        yield put(setTweetSentState(TweetSentState.ERROR));
    }
}

export function* updateScheduledTweetRequest({ payload }: UpdateScheduledTweetActionInterface) {
    try {
        yield put(setTweetSentState(TweetSentState.IDEL));
        const response: AxiosResponse<TweetResponse> = yield call(ScheduledTweetApi.updateScheduledTweet, payload);
        if (response.data) {
            yield put(setTweetSentState(TweetSentState.SENT));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
        yield put(setTweetSentState(TweetSentState.ERROR));
    }
}

export function* addQuoteTweetRequest({ payload }: AddQuoteTweetActionInterface) {
    try {
        yield put(setTweetSentState(TweetSentState.IDEL));
        const response: AxiosResponse<TweetResponse> = yield call(TweetApi.quoteTweet, payload);
        if (response.data) {
            yield put(setTweetSentState(TweetSentState.SENT));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
        yield put(setTweetSentState(TweetSentState.ERROR));
    }
}

export function* voteRequest({ payload }: VoteActionInterface) {
    try {
        yield call(PollApi.voteInPoll, payload);
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* changeReplyTypeRequest({ payload }: ChangeReplyTypeActionInterface) {
    try {
        yield call(TweetApi.changeTweetReplyType, payload);
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchDeleteTweetRequest({ payload }: FetchDeleteTweetActionInterface) {
    try {
        yield call(TweetApi.deleteTweet, payload);
        yield put(deleteUserTweet(payload));
        yield put(deleteListTweet(payload));
        yield put(deleteTweet(payload));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* deleteScheduledTweetsTweetRequest({ payload }: DeleteScheduledTweetsActionInterface) {
    try {
        yield call(ScheduledTweetApi.deleteScheduledTweets, payload);
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* likeTweetRequest({ payload }: LikeTweetActionInterface) {
    try {
        const response: AxiosResponse<NotificationTweetResponse> = yield call(LikeTweetApi.likeTweet, payload);
        if (response && payload?.isExplore) {
            yield put(setTweetLikeCount({tweetId: payload?.tweetId}));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* retweetRequest({ payload }: RetweetActionInterface) {
    try {
        const response: AxiosResponse<NotificationTweetResponse> = yield call(RetweetApi.retweet, payload);
        if (response && payload?.isExplore) {
            yield put(setRetweetCount({tweetId: payload?.tweetId}));
        }
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchUserBookmarksRequest({ payload }: FetchBookmarksActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(BookmarkApi.getUserBookmarks, payload);
        yield put(setPageableTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchTopTweetsRequest({ payload }: FetchTopTweetsActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getTopTweets, payload);
        yield put(setPageableExploreTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchLatestTweetsRequest({ payload }: FetchLatestTweetsActionInterface) {
    try {
        yield put(setTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetResponse[]> = yield call(TweetApi.getLatestTweets, payload);
        yield put(setPageableExploreTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
    } catch (error) {
        yield put(setTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchDeclinedTweetsRequest({payload}: FetchDeclinedTWeetsAcionInterface) {
    try {
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<any> = yield call(TweetApi.getDeclinedTweets, payload);
        yield put(setDeclinedTweets({
            items: response.data,
            pagesCount: parseInt(response.headers[PAGE_TOTAL_COUNT])
        }));
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.LOADED));
    } catch (error) {
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* declinedTweetAppealApply({ payload }: SetDeclinedApplyAppealActionInterface) {
    try {
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<any> = yield call(TweetApi.appealApply, payload);

        // Show message
        if (response?.data?.message) {
            yield put(setOpenSnackBar({
                message: response?.data?.message
            }))
        }
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.LOADED));
    } catch (error) {
        yield put(setDeclinedTweetsLoadingState(LoadingStatus.ERROR));
    }
}

export function* tweetsSaga() {
    yield takeLatest(TweetsActionType.FETCH_TWEETS, fetchTweetsRequest);
    yield takeLatest(TweetsActionType.FETCH_MEDIA_TWEETS, fetchMediaTweetsRequest);
    yield takeLatest(TweetsActionType.FETCH_TWEETS_WITH_VIDEO, fetchTweetsWithVideoRequest);
    yield takeLatest(TweetsActionType.FETCH_FOLLOWERS_TWEETS, fetchFollowersTweetsRequest);
    yield takeLatest(TweetsActionType.ADD_TWEET, addTweetRequest);
    yield takeLatest(TweetsActionType.ADD_POLL, addPollRequest);
    yield takeLatest(TweetsActionType.ADD_SCHEDULED_TWEET, addScheduledTweetRequest);
    yield takeLatest(TweetsActionType.UPDATE_SCHEDULED_TWEET, updateScheduledTweetRequest);
    yield takeLatest(TweetsActionType.ADD_QUOTE_TWEET, addQuoteTweetRequest);
    yield takeLatest(TweetsActionType.VOTE, voteRequest);
    yield takeLatest(TweetsActionType.CHANGE_REPLY_TYPE, changeReplyTypeRequest);
    yield takeLatest(TweetsActionType.FETCH_DELETE_TWEET, fetchDeleteTweetRequest);
    yield takeLatest(TweetsActionType.DELETE_SCHEDULED_TWEETS, deleteScheduledTweetsTweetRequest);
    yield takeLatest(TweetsActionType.LIKE_TWEET, likeTweetRequest);
    yield takeLatest(TweetsActionType.RETWEET, retweetRequest);
    yield takeLatest(TweetsActionType.FETCH_TWEETS_BY_TAG, fetchTweetsByTagRequest);
    yield takeLatest(TweetsActionType.FETCH_TWEETS_BY_TEXT, fetchTweetsByTextRequest);
    yield takeLatest(TweetsActionType.FETCH_TWEETS_WITH_QUOTES_BY_ID, fetchQuotesByTweetIdRequest);
    yield takeLatest(TweetsActionType.FETCH_BOOKMARKS, fetchUserBookmarksRequest);
    yield takeLatest(TweetsActionType.FETCH_TOP_TWEETS, fetchTopTweetsRequest);
    yield takeLatest(TweetsActionType.FETCH_LATEST_TWEETS, fetchLatestTweetsRequest);
    yield takeLatest(TweetsActionType.FETCH_DECLINED_TWEETS, fetchDeclinedTweetsRequest);
    yield takeLatest(TweetsActionType.SET_DECLINED_APPLY_APPEAL, declinedTweetAppealApply);
}
