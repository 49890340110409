import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../../../constants/common-constants";
import { BackgroundTheme } from "../../../../types/common";

export const useAppealModalStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 680,
        height: 400,
        textAlign: "center",
        margin: "22px 20px",
        "& svg": {
            color: theme.palette.primary.main,
            fontSize: 45
        },
        "& .MuiTypography-subtitle1": {
            marginTop: 8,
            marginBottom: 24
        },
        "& .MuiButton-root": {
            width: 134,
            "& .MuiButton-label": {
                fontSize: 15,
                lineHeight: "20px"
            }
        }
    },
    modalButtonWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    modalCancelButton: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.divider
    },
    modalDeleteButton: {
        "&.MuiButton-contained": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main
        },
        "&.MuiButton-contained:hover": {
            backgroundColor: theme.palette.error.dark
        }
    },
    modalPrimaryButton: {
        "&.MuiButton-contained": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        },
        "&.MuiButton-contained:hover": {
            backgroundColor: "rgb(26, 145, 218)"
        }
    },
    contentTextarea: {
        width: "100%",
        border: 0,
        fontSize: 20,
        outline: "none",
        fontFamily: "inherit",
        resize: "none",
        backgroundColor: "transparent",
        padding: "5px 15px",
        caretColor: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "#000" : "#fff",
        color: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "#000" : "#fff"
    },
    errorAlign: {
        textAlign: "left",
        color: "#ff6c6c",
        marginBottom: "10px"
    }
}))