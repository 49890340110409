import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";

import {
    setIsTweetBookmarkedAdditionalInfo,
    setTweetAdditionalInfo,
    setTweetAdditionalInfoLoadingState,
    setVeracityErrorMessage,
    setVeracityTweetInfoLoadingState,
    setVeracityTweetList
} from "./actionCreators";
import {
    FetchIsTweetBookmarkedAdditionalInfoActionInterface,
    FetchTweetAdditionalInfoActionInterface,
    FetchVeracityInfoActionInterface,
    TweetAdditionalInfoType
} from "./contracts/actionTypes";
import { TweetAdditionalInfoResponse } from "../../../types/tweet";
import { LoadingStatus } from "../../../types/common";
import { BookmarkApi } from "../../../services/api/tweet-service/bookmarkApi";
import { TweetApi } from "../../../services/api/tweet-service/tweetApi";

export function* fetchTweetAdditionalInfoRequest({ payload }: FetchTweetAdditionalInfoActionInterface) {
    try {
        yield put(setTweetAdditionalInfoLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<TweetAdditionalInfoResponse> = yield call(TweetApi.getTweetAdditionalInfoById, payload);
        yield put(setTweetAdditionalInfo(response.data));
    } catch (error) {
        yield put(setTweetAdditionalInfoLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchIsTweetBookmarkedAdditionalInfoRequest({ payload }: FetchIsTweetBookmarkedAdditionalInfoActionInterface) {
    try {
        yield put(setTweetAdditionalInfoLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<boolean> = yield call(BookmarkApi.getIsTweetBookmarked, payload);
        yield put(setIsTweetBookmarkedAdditionalInfo(response.data));
    } catch (error) {
        yield put(setTweetAdditionalInfoLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchVeracityInfoRequest({ payload }: FetchVeracityInfoActionInterface) {
  try {
    yield put(setVeracityErrorMessage(""));
    yield put(setVeracityTweetInfoLoadingState(LoadingStatus.LOADING));
    const tweetResponse:AxiosResponse<any> = yield call(TweetApi.getVeracityTweet, payload);
    const veracityData = tweetResponse.data;
    const veracityResponse = {
      ...veracityData.verification_record,
      userScore: veracityData?.userScore,
      tweetId: payload.tweet_id
    }
    yield put(setVeracityTweetList(veracityResponse));
  } catch (error) {
    yield put(setVeracityErrorMessage(error?.response?.data?.message || "Something went wrong (VE)"));
    yield put(setTweetAdditionalInfoLoadingState(LoadingStatus.ERROR));
    yield put(setVeracityTweetInfoLoadingState(LoadingStatus.ERROR));
  }
}

export function* tweetAdditionalInfoSaga() {
    yield takeLatest(TweetAdditionalInfoType.FETCH_TWEET_ADDITIONAL_INFO, fetchTweetAdditionalInfoRequest);
    yield takeLatest(TweetAdditionalInfoType.FETCH_IS_TWEET_BOOKMARKED_ADDITIONAL_INFO, fetchIsTweetBookmarkedAdditionalInfoRequest);
    yield takeLatest(TweetAdditionalInfoType.FETCH_VERACITY_TWEET_INFO, fetchVeracityInfoRequest);
}
