import { Typography } from "@material-ui/core"
import PageHeaderWrapper from "../../../components/PageHeaderWrapper/PageHeaderWrapper"

const DeclinedTweetsHeader = () => {
    return (
        <PageHeaderWrapper backButton>
            <div>
                <Typography variant={"h5"} component={"span"}>
                    {`Declined Tweets`}
                </Typography>
            </div>
        </PageHeaderWrapper>
    )
}

export default DeclinedTweetsHeader;