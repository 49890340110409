import React, { FC, memo, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useGifImageStyles } from "./GifImageStyles";
import ActionIconButton from "../ActionIconButton/ActionIconButton";
import { CloseIcon } from "../../icons";
import { GifImageResponse } from "../../types/tweet";
import { HOME_TWEET } from "../../constants/path-constants";
import { removeGif } from "../../store/ducks/addTweetForm/actionCreators";
import { BACKGROUND } from "../../constants/common-constants";
import { BackgroundTheme } from "../../types/common";

interface GifImageProps {
    tweetId?: string;
    gifImage?: GifImageResponse;
    removeButton?: boolean;
    withLink?: boolean;
}

interface WithLinkProps {
    children: ReactNode;
    withLink?: boolean;
    tweetId?: string;
}

const WithLink = ({ children, withLink, tweetId }: WithLinkProps) =>
    withLink ? <Link to={`${HOME_TWEET}/${tweetId}`}>{children}</Link> : <>{children}</>;

const GifImage: FC<GifImageProps> = memo(({ tweetId, gifImage, removeButton, withLink }): ReactElement => {
    const classes = useGifImageStyles({ width: gifImage!.width, height: gifImage!.height });
    const dispatch = useDispatch();
    const backgroundColor = localStorage.getItem(BACKGROUND);

    const onClickRemoveGif = (): void => {
        dispatch(removeGif());
    };

    return (
        <WithLink withLink={withLink} tweetId={tweetId}>
            <div className={classes.gif}>
                <img src={gifImage?.url} alt={""} />
                {removeButton && (
                    <>
                        <div className={classes.poweredByGiphy}>{backgroundColor === BackgroundTheme.DEFAULT ? (
                            <img src="poweredby_giphy_white.png" width={100} />
                        ) : (
                            <img src="poweredby_giphy.png"  width={100} />
                        )}</div>
                        <div className={classes.gifRemove}>
                            <ActionIconButton
                                actionText={"Remove"}
                                icon={CloseIcon}
                                onClick={onClickRemoveGif}
                                size={"medium"}
                            />
                        </div>
                    </>
                )}
            </div>
        </WithLink>
    );
});

export default GifImage;
