import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent, Slider, Button, IconButton, DialogActions } from "@material-ui/core";
import { useVideoTrimingStyles } from "./VideoTrimingModalStyles";
import { useDispatch } from "react-redux";
import { setOpenSnackBar } from "../../../store/ducks/actionSnackbar/actionCreators";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { setImages } from "../../../store/ducks/addTweetForm/actionCreators";
import { ActionSnackbarStateType } from "../../../store/ducks/actionSnackbar/contracts/state";

const ffmpeg = new FFmpeg();

interface IProps {
    videoFiles: File[]; // List of video files to display in the modal
    visible: boolean; // Visibility state of the modal
    onClose: () => void; // Function to close the modal
}

interface IVideoProps {
    video: File; // Individual video file
    type: string; // MIME type of the video
    onTrimChange: (file: File, start: number, end: number) => void; // Callback to update trim range
    trimmedVideos: Map<string, { start: number; end: number }>; // Map to track trimmed ranges
    onTrim: () => void; // Callback to trim video
    setFinalVideos: (d: any) => void;
}

const RenderVideo: React.FC<IVideoProps> = ({ video, type, onTrimChange, trimmedVideos, setFinalVideos, onTrim }) => {
    const [range, setRange] = useState<[number, number]>([0, 30]); // Local state for trimming range
    const videoRef = useRef<HTMLVideoElement | null>(null); // Reference to the video element
    const [isProcessing, setIsProcessing] = useState(false); // State to indicate processing status
    const classes = useVideoTrimingStyles(); // Styles for the modal
    const dispatch = useDispatch();

    const handleChange = (event: any, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            // Ensure the trimming range is within video duration and 30 seconds
            const videoDuration = videoRef.current?.duration || 0;
            const maxEnd = Math.min(newValue[0] + 30, videoDuration);
            const adjustedRange: [number, number] = [newValue[0], Math.min(newValue[1], maxEnd)];
            setRange(adjustedRange);
            onTrimChange(video, adjustedRange[0], adjustedRange[1]);
        }
    };

    const handleTrim = async () => {
        setIsProcessing(true);
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }

        const { start, end } = trimmedVideos.get(video.name) || { start: 0, end: 30 };

        ffmpeg.writeFile(video.name, await fetchFile(video));

        const outputFileName = `${video.name}`;
        await ffmpeg.exec([
            "-i",
            video.name,
            "-ss",
            `${start}`, // Start time
            "-to",
            `${end}`, // End time
            "-c",
            "copy",
            outputFileName
        ]);

        const data = await ffmpeg.readFile(outputFileName);
        const trimmedVideo = new Blob([data], { type });

        const trimmingVideoUrl = URL.createObjectURL(trimmedVideo);
        const imageObj = [
            {
                src: trimmingVideoUrl,
                file: new File([trimmedVideo], outputFileName, {
                    type
                })
            }
        ];
        setFinalVideos((prev: any) => new Map(prev.set(video.name, { file: video})));
        dispatch(setImages(imageObj));
        onTrimChange(video, start, end);
        setIsProcessing(false);
        onTrim(); // Go to next index
    };

    return (
        <div className={classes.videoContainer}>
            <video
                className={classes.video}
                ref={videoRef}
                onLoadedMetadata={(e) => {
                    const videoElement = e.target as HTMLVideoElement;
                    const duration = videoElement.duration;
                    setRange([0, Math.min(30, duration)]);
                }}
                controls
            >
                <source src={URL.createObjectURL(video)} type={type} />
            </video>
            <Slider
                value={range}
                onChange={handleChange}
                max={videoRef.current?.duration || 30}
                min={0}
                valueLabelDisplay="on"
                className={classes.slider}
            />
            <Button
                onClick={handleTrim}
                color="primary"
                className={classes.trimButton}
                variant="contained"
                disabled={isProcessing}
            >
                {isProcessing ? "Processing..." : `Trim ${video.name}`}
            </Button>
        </div>
    );
};

const VideoTrimingModal: React.FC<IProps> = ({ videoFiles, visible, onClose }) => {
    const classes = useVideoTrimingStyles(); // Styles for the modal
    const dispatch = useDispatch(); // Redux dispatch for snackbar notifications
    const [finalVideos, setFinalVideos] = useState<Map<string, { file: File; }>>(new Map());
    const [trimmedVideos, setTrimmedVideos] = useState<Map<string, { start: number; end: number }>>(new Map());
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0); // Current video index for carousel

    const handleTrimChange = (file: File, start: number, end: number) => {
        setTrimmedVideos((prev) => new Map(prev.set(file.name, { start, end })));
    };

    const handleClose = () => {
        if (finalVideos.size !== videoFiles.length) {
            dispatch(setOpenSnackBar({
                message: "All videos must be trimmed before closing.",
                type: ActionSnackbarStateType.WARNING
            }));
            return;
        }
        onClose();
    };

    const handleNext = () => {
        setCurrentVideoIndex((prevIndex) => Math.min(prevIndex + 1, videoFiles.length - 1));
    };

    const handlePrevious = () => {
        setCurrentVideoIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    // 
    // useEffect(() => {
    //     if (trimmedVideos.size === videoFiles.length) {
    //         // handleClose();
    //     }
    // }, [trimmedVideos, videoFiles]);

    return (
        <Dialog transitionDuration={0} open={visible} onClose={handleClose} className={classes.dialog} maxWidth="xl">
            <DialogActions className={classes.dialogActions}>
                <Button color="secondary" variant="contained" onClick={handleClose} disabled={finalVideos.size !== videoFiles.length}>
                    Done
                </Button>
            </DialogActions>
            <DialogContent className={classes.content}>
                <div className={classes.carouselContainer}>
                    {videoFiles[currentVideoIndex] && (
                        <>
                            <button
                                className={classes.arrowPrev}
                                onClick={handlePrevious}
                                style={{
                                    display: videoFiles.length === 1 || currentVideoIndex === 0 ? "none" : "block"
                                }}
                            >
                                <WestIcon />
                            </button>
                            <RenderVideo
                                key={videoFiles[currentVideoIndex].name}
                                video={videoFiles[currentVideoIndex]}
                                type={videoFiles[currentVideoIndex].type}
                                onTrimChange={handleTrimChange}
                                trimmedVideos={trimmedVideos}
                                onTrim={handleNext}
                                setFinalVideos={setFinalVideos}
                            />
                            <button
                                className={classes.arrowNext}
                                onClick={handleNext}
                                style={{
                                    display:
                                        videoFiles.length === 1 || currentVideoIndex === videoFiles.length - 1
                                            ? "none"
                                            : "block"
                                }}
                            >
                                <EastIcon />
                            </button>
                            {/* <IconButton onClick={handleNext} disabled={currentVideoIndex === videoFiles.length - 1}>
                                <ArrowForwardIosIcon />
                            </IconButton> */}
                        </>
                    )}
                </div>
            </DialogContent>
            {/* <Button onClick={handleClose} color="primary" variant="contained">
                Close
            </Button> */}
        </Dialog>
    );
};

export default VideoTrimingModal;
