import { makeStyles, Theme } from "@material-ui/core";

interface TweetImageStylesProps {
    isDeclined: boolean;
}

export const useTweetImageStyles = makeStyles<Theme, TweetImageStylesProps>((theme) => ({
    container: {
        zIndex: 12,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.85)",
        cursor: "auto"
    },
    containerInner: {
        height: "100%",
        display: "flex",
        position: "relative"
    },
    closeWrapper: {
        position: "absolute",
        top: 10,
        left: 10
    },
    preViewWrapper: {
        width: props => props.isDeclined ? "100%" : "calc(100% - 359px)",
        padding: 20
    },
    preViewInner: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100%"
    },
    carouselWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%"
    },
    arrowPrev: {
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderRadius: "50%",
        outline: 0,
        border: 0,
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        "& svg": {
            color: theme.palette.common.white
        }
    },
    arrowNext: {
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderRadius: "50%",
        outline: 0,
        border: 0,
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        "& svg": {
            color: theme.palette.common.white
        }
    },
    imgWrapper: {
        height: "90%",
        position: "relative",
        width: "90%",
        margin: "0 10px",
        "& img": {
            objectFit: "contain",
            bottom: 20,
            height: "100%",
            position: "absolute",
            top: 20,
            width: "100%",
            zIndex: "-1"
        },
        "& video": {
            objectFit: "contain",
            bottom: 20,
            height: "100%",
            position: "absolute",
            top: 20,
            width: "100%",
            zIndex: "90"
        }
    },
    modalWrapper: {
        backgroundColor: theme.palette.background.paper,
        width: 359,
        height: "100%",
        float: "right",
        overflowY: "scroll",
        overflowX: "hidden"
    },
    tweetInfo: {
        padding: "0 16px"
    },
    tweetFooter: {
        display: "flex",
        position: "relative",
        paddingTop: 5,
        paddingBottom: 5,
        margin: "0 auto",
        borderTop: `1px solid ${theme.palette.divider}`,
        left: 0,
        maxWidth: "100%",
        justifyContent: "space-around",
        padding: "2px 0"
    },
    divider: {
        height: 12,
        backgroundColor: theme.palette.divider
    },
    imageFooterContainer: {
        width: 448,
        height: 48,
        "& svg": {
            color: theme.palette.common.white
        }
    },
    imageFooterWrapper: {
        display: "flex",
        paddingTop: 5,
        paddingBottom: 5,
        justifyContent: "space-between",
        maxWidth: 450
    },
    arrowWrapper: {
        width: "40px"
    }
}));
