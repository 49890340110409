import { makeStyles } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";

export const useAddTweetImageStyles = makeStyles((theme) => ({
    image: {
        position: "relative",
        "& img": {
            objectFit: "cover",
            marginTop: 10,
            width: "100%",
            height: "auto",
            borderRadius: 20,
            borderColor: theme.palette.info.light
        },
        "& video": {
            objectFit: "cover",
            marginTop: 10,
            height: "200px",
            borderRadius: 20,
            borderColor: theme.palette.info.light
        }
    },
    imageSmall: {
        position: "relative",
        // "& img": {
        //     objectFit: "cover",
        //     marginTop: 10,
        //     width: 260,
        //     height: 152,
        //     borderRadius: 20,
        //     borderColor: theme.palette.info.light
        // }
    },
    imageWrapper:{
      position:"relative",
      display: "flex",
      justifyContent: "center",
      "& .MuiIconButton-root": {
        padding: 6,
        top: 15,
        left: 5,
        position: "absolute",
        backgroundColor: theme.palette.common.black,
        opacity: 0.75,
        zIndex:2,
        "& svg": {
            verticalAlign: "top",
            fill: theme.palette.common.white,
            width: 18,
            height: 18
        },
        "&:hover": {
            backgroundColor: "rgba(39, 44, 48, 0.75) !important"
        }
    },
    "& img": {
            objectFit: "cover",
            // marginTop: 10,
            // width: 260,
            height: 260,
            borderRadius: 20,
            borderColor: theme.palette.info.light
        }
      
    },
    arrowPrev:{
        height:40,
        width:40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding:10,
        borderRadius:"50%",
        outline:0,
        border:0,
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        position:"absolute",
        left: 10, 
        zIndex:1,
            top: "50%",
            transform: "translateY(-50%)",
    "& svg":{
            color: theme.palette.common.white,
            height: "unset",
            width: "unset",
        },
    },
    arrowNext:{
        height:40,
        width:40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding:10,
        borderRadius:"50%",
        outline:0,
        border:0,
        position:"absolute",
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        right: 10, 
        zIndex:1,
            top: "50%",
            transform: "translateY(-50%)",
    "& svg":{
            color: theme.palette.common.white,
            height: "unset",
            width: "unset",
        },
    },
}));
