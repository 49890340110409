import React, { ReactElement } from "react";
import { Link as MuiLink, Typography } from "@material-ui/core";

import { useChatUserRestrictionStyles } from "./ChatUserRestrictionStyles";
// import { DIRECT_MESSAGES } from "../../../../constants/url-constants";

interface IProps {
    isDeleted?: boolean;
    userName?: string;
}
const ChatUserRestriction = ({ isDeleted, userName }: IProps): ReactElement => {
    const classes = useChatUserRestrictionStyles();

    return (
        <Typography variant={"subtitle2"} component={"div"} className={classes.blockedInfoText}>
            {isDeleted && `@${userName} is deleted :`} You can no longer send messages to this person.
            {" "}
            {/* <MuiLink variant="subtitle2" target="_blank" rel="noopener">
                Learn more
            </MuiLink> */}
        </Typography>
    );
};

export default ChatUserRestriction;
