import React, { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogContent, List, ListItem, Typography } from "@material-ui/core";

import { useListsModalStyles } from "./ListsModalStyles";
import {
    selectIsSimpleListsLoaded,
    selectIsSimpleListsLoading,
    selectSimpleListsItems,
    selectUserListsItems
} from "../../store/ducks/lists/selectors";
import { fetchSimpleLists, processUserToLists, resetListsState } from "../../store/ducks/lists/actionCreators";
import { SimpleListResponse } from "../../types/lists";
import Spinner from "../Spinner/Spinner";
import ListsModalItem from "./ListsModalItem/ListsModalItem";
import DialogTitleComponent from "../DialogTitleComponent/DialogTitleComponent";
import { useGlobalStyles } from "../../util/globalClasses";
import { useModalWindow } from '../../hook/useModalWindow';
import CreateListsModal from '../../pages/Lists/ListsHeader/CreateListsModal/CreateListsModal';

interface ListsModalProps {
    userId: string;
    visible?: boolean;
    onClose: () => void;
}
interface ListRequest {
  listId: string;
  isMemberInList: boolean;
}

const ListsModal: FC<ListsModalProps> = ({ userId, visible, onClose }): ReactElement | null => {
    const globalClasses = useGlobalStyles({ dialogContentHeight: 600 });
    const classes = useListsModalStyles();
    const dispatch = useDispatch();
    const simpleLists = useSelector(selectSimpleListsItems);
    const isSimpleListsLoading = useSelector(selectIsSimpleListsLoading);
    const isSimpleListsLoaded = useSelector(selectIsSimpleListsLoaded);
    const userLists = useSelector(selectUserListsItems);
    const { visibleModalWindow, onOpenModalWindow, onCloseModalWindow } = useModalWindow();
    const [lists, setLists] = useState<SimpleListResponse[]>([]);
    const [selectedItem, setSelectedItem] = useState<SimpleListResponse[]>([]);

    useEffect(() => {
        if (visible && userId) {
            dispatch(fetchSimpleLists(userId));
        } else if (userLists?.length && userId && visible) {
          dispatch(fetchSimpleLists(userId));
        }
    }, [dispatch, userId, visible, userLists]);

    useEffect(() => {
        setLists(simpleLists);
    }, [isSimpleListsLoaded]);

    const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const listsRequest = simpleLists.reduce<ListRequest[]>((acc, list) => {
          const data = selectedItem.find(item => item.id === list.id && item.isMemberInList !== list.isMemberInList);
          if (data) {
              acc.push({
                  listId: data.id,
                  isMemberInList: data.isMemberInList,
              });
          }
          return acc;
        }, []);
        dispatch(processUserToLists({ userId, lists: listsRequest }));
        dispatch(resetListsState());
        setLists([]);
        onClose();
    };

    const onSelect = (listId: string): void => {
      const listsCopy = [...lists];
      const index = listsCopy.findIndex((list) => list.id === listId);
      listsCopy[index] = { ...listsCopy[index], isMemberInList: !listsCopy[index].isMemberInList };
      
      // Check if the list is already in selectedLists
      const selectedListIndex = selectedItem.findIndex((list) => list.id === listId);
      
      let updatedSelectedLists;
      if (selectedListIndex !== -1) {
          // Update the existing entry
          updatedSelectedLists = selectedItem.map((list, i) => 
              i === selectedListIndex ? listsCopy[index] : list
          );
      } else {
          // Add the new entry
          updatedSelectedLists = [...selectedItem, listsCopy[index]];
      }
      setSelectedItem(updatedSelectedLists);
      setLists(listsCopy);
    };

    if (!visible) {
        return null;
    }

    return (
        <Dialog open={visible} onClose={onClose} >
            <form onSubmit={onSubmit}>
                <DialogTitleComponent title={"Pick a List"} onClose={onClose}>
                    <Button type="submit" variant="contained" color="primary" size="small">
                        Save
                    </Button>
                </DialogTitleComponent>
                <DialogContent className={globalClasses.dialogContent}>
                    <Typography variant={"body1"} component={"div"} className={classes.createList} onClick={onOpenModalWindow}>
                        Create a new List
                    </Typography>
                    <div className={classes.list}>
                        {isSimpleListsLoading ? (
                            <Spinner />
                        ) : (
                            <List>
                                {lists.map((list) => (
                                    <ListItem
                                        key={list.id}
                                        onClick={() => onSelect(list.id)}
                                        selected={list.isMemberInList}
                                        dense
                                        button
                                    >
                                        <ListsModalItem list={list} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </div>
                </DialogContent>
            </form>
            <CreateListsModal visible={visibleModalWindow} onClose={onCloseModalWindow} />
        </Dialog>
    );
};

export default ListsModal;
