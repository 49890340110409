import React, { FC, memo, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LikeIcon, LikeOutlinedIcon } from "../../../icons";
import { useLikeIconButtonStyles } from "./LikeIconButtonStyles";
import ActionIconButton from "../../../components/ActionIconButton/ActionIconButton";
import { likeTweet } from "../../../store/ducks/tweets/actionCreators";
import { selectIsTweetLiked } from "../../../store/ducks/tweet/selectors";
import { selectUserDataId } from '../../../store/ducks/user/selectors';
import { useGlobalStyles } from "../../../util/globalClasses";
import classNames from "classnames";

interface LikeIconButtonProps {
  likesCount?: number;
}

const LikeIconButton: FC<LikeIconButtonProps> = memo(({ likesCount }
  ): ReactElement => {
    const isTweetLiked = useSelector(selectIsTweetLiked);
    const myProfileId = useSelector(selectUserDataId);

    const globalClasses = useGlobalStyles({});
    const classes = useLikeIconButtonStyles({ isTweetLiked: isTweetLiked! });
    
    const [isLike, setIsLike] = React.useState(isTweetLiked);
    const [tweetLikeCount, setTweetLikeCount] = React.useState<number>(likesCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);
    
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    const handleLike = (): void => {
        setIsLike(!isLike);
        setTweetLikeCount((prevState) => isLike ? --prevState : ++prevState);
        setAnimationLike(isLike ? globalClasses.countGoDown : globalClasses.countGoUp);
        setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        dispatch(likeTweet({ tweetId: params.id, userId: myProfileId }));
    };

    // Update like counts on props changes
    React.useEffect(() => {
        if (likesCount !== undefined && likesCount !== tweetLikeCount) {
            setTweetLikeCount(likesCount);
            setAnimationLike(likesCount < tweetLikeCount ? globalClasses.countGoDown : globalClasses.countGoUp);
            setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        }
    }, [likesCount])

    return (
        <div className={classes.likeIcon}>
            <ActionIconButton
                actionText={isLike ? "Unlike" : "Like"}
                onClick={handleLike}
                icon={isLike ? LikeIcon : LikeOutlinedIcon}
            />
            {(tweetLikeCount !== 0) && (
              <span id={"likesCount"} className={classNames(classes.likesCount, animationLike)}>
                  {tweetLikeCount}
              </span>
            )}
        </div>
    );
});

export default LikeIconButton;
