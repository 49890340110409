import { makeStyles } from "@material-ui/core";

export const useAddDescriptionModalStyles = makeStyles(() => ({
    contentImage: {
        display: "flex",
        justifyContent: "center",
        "& img": {
            maxHeight: "500px",
            maxWidth: "100%",
            borderRadius: "5px"
        }
    },
    dialogContent: {
        maxWidth: "100%",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    inputWrapper: {
        width: 560,
        height: 22,
        display: "flex",
        justifyContent: "space-between"
    }
}));
