import { Chip } from "@material-ui/core";
import React from "react";
import { useDeclinedTweetHeaderStyles } from "./DeclinedTweetHeaderStyles";

interface IPorps {
    declinedTweet: any;
    webhookResponse: any;
}

const DeclinedTweetHeader = ({ declinedTweet, webhookResponse }: IPorps) => {
    const classes = useDeclinedTweetHeaderStyles();

    const policyNames = React.useMemo(() => {
        return webhookResponse?.policyName?.split(",");
    }, [webhookResponse]);

    const tagNames = React.useMemo(() => {
        return declinedTweet?.tag?.split(",");
    }, [declinedTweet]);

    return (
        <div className={classes.policyNames}>
            {policyNames && policyNames?.map((policyName: string) => (
                <Chip
                    key={policyName}
                    label={policyName}
                    color="primary"
                    size={`small`}
                    variant="default"
                />
            ))}
            {webhookResponse?.tag &&
                tagNames.length &&
                tagNames?.map((tag: string) => (
                    <Chip key={tag} label={tag} color="default" size={`small`} variant="default" />
                ))}
        </div>
    );
};

export default DeclinedTweetHeader;
