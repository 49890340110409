import { makeStyles } from "@material-ui/core";
import { BACKGROUND } from "../../../../constants/common-constants";
import { BackgroundTheme } from "../../../../types/common";

export const useFullDeclinedTweetModalStyles = makeStyles((theme) => ({
    modalWrapper: {
        // width: 400,
        // height: 160,
        margin: "20px 20px",
        "& svg": {
            color: theme.palette.primary.main,
            fontSize: 45
        },
        "& .MuiTypography-subtitle1": {
            marginTop: 8,
            marginBottom: 24
        },
        "& .MuiButton-root": {
            width: 134,
            "& .MuiButton-label": {
                fontSize: 15,
                lineHeight: "20px"
            }
        }
    },
    modalButtonWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    modalCancelButton: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.divider
    },
    modalDeleteButton: {
        "&.MuiButton-contained": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main
        },
        "&.MuiButton-contained:hover": {
            backgroundColor: theme.palette.error.dark
        }
    },
    modalPrimaryButton: {
        "&.MuiButton-contained": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        },
        "&.MuiButton-contained:hover": {
            backgroundColor: "rgb(26, 145, 218)"
        }
    },
    contentTextarea: {
        width: "100%",
        border: 0,
        fontSize: 20,
        outline: "none",
        fontFamily: "inherit",
        resize: "none",
        backgroundColor: "transparent",
        padding: "5px 15px",
        caretColor: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "#000" : "#fff",
        color: localStorage.getItem(BACKGROUND) === BackgroundTheme.DEFAULT ? "#000" : "#fff"
    },
    content: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    contentText: {
        fontSize: "16px"
    },
    tweetWrapper: {
        display: "flex",
        alignItems: "flex-start",
        flex: 1,
        width: "90%"
    },
    tweetContainer: {
        flex: 1,
        marginLeft: 15,
    },
    headerWrapper: {
        color: "inherit",
        textDecoration: "none",
        "& #hashtag": {
            color: theme.palette.primary.main
        }
    },
    header: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 20,
        "& .MuiTypography-h6": {
            fontWeight: 700
        }
    },
    headerIcon: {
        padding: 0,
        marginRight: 12,
        "& svg": {
            color: theme.palette.text.secondary,
            height: "0.8em"
        }
    },
    tweetContent: {
        // width: 500
        "& img": {
            
        }
    },
    image: {
        position: "relative",
        "& img": {
            objectFit: "cover",
            marginTop: 10,
            width: 504,
            height: 252,
            borderRadius: 20,
            borderColor: theme.palette.info.light
        },
        "& .small": {
            width: 260
        }
    },
    footer: {
        display: "flex",
        position: "relative",
        paddingTop: 5,
        paddingBottom: 5,
        left: -8,
        justifyContent: "space-between",
        maxWidth: 450
    },
    popperUserWindow: {
        position: "absolute",
        width: 100,
        height: 100,
        border: "1px solid black",
        backgroundColor: theme.palette.common.white
    },
    tweetHeader: {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px"
    },
    replyWrapper: {
        margin: "16px 60px",
        "& a": {
            textDecoration: "none",
            color: theme.palette.primary.main
        }
    },
    container: {
        width: "100%",
        display: "flex",
    },
    status: {
        width: "10%"
    },
    pendingChip: {
        backgroundColor: "rgb(255, 167, 38)",
        color: "black",
        textTransform: "capitalize"
    },
    errorChip: {
        backgroundColor: "rgb(244, 67, 54)",
        color: "black",
        textTransform: "capitalize"
    },
    approveChip:{
        backgroundColor: "rgb(102, 187, 106)",
        color: "black",
        textTransform: "capitalize"
    }
}))