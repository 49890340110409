import { makeStyles } from "@material-ui/core";

export const useChatUserRestrictionStyles = makeStyles(() => ({
    blockedInfoText: {
        textAlign: "center",
        height: 30,
        position: "fixed",
        bottom: 0,
        display: "flex",
        width: 598,
        justifyContent: "center"
    }
}));
