import React, { ChangeEvent, FC, memo, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogContent, FormControl, InputLabel, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useChangePhoneModalStyles } from "./ChangePhoneModalStyles";
import { AppIcon } from "../../../../../../icons";
import { ChangeInfoTextField } from "../../../../ChangeInfoTextField/ChangeInfoTextField";
import { FilledSelect } from "../../../../../../components/FilledSelect/FilledSelect";
import {
    selectUserIsLoading,
    selectUserProfilePhoneNumber,
    selectUserProfilePhoneCode
} from "../../../../../../store/ducks/user/selectors";
import { setUserLoadingStatus, updatePhone } from "../../../../../../store/ducks/user/actionCreators";
// import { EMAIL_AND_PHONE_DISCOVERABILITY_SETTINGS } from "../../../../../../constants/url-constants";
import { fetchCountryCodes } from "../../../../../../store/ducks/localization/actionCreators";
import { selectCountryCodes, selectIsLocalizationLoading } from "../../../../../../store/ducks/localization/selectors";
import { LoadingStatus } from "../../../../../../types/common";

interface ChangePhoneModalProps {
    visible?: boolean;
    onClose: () => void;
    isPhoneNumberValid: boolean;
}

export interface PhoneFormProps {
    phoneNumber: string;
}

const SetPhoneFormSchema = yup.object().shape({
    // Updated Mobile number validation
    phoneNumber: yup
        .string()
        .matches(/^[0-9]\d{8,10}$/, "Please enter a valid phone number.")
        .required()
});

const ChangePhoneModal: FC<ChangePhoneModalProps> = memo(
    ({ visible, onClose, isPhoneNumberValid }): ReactElement | null => {
        const classes = useChangePhoneModalStyles();
        const dispatch = useDispatch();
        const countryCodes = useSelector(selectCountryCodes);
        const isCountryCodesLoading = useSelector(selectIsLocalizationLoading);
        const profilePhoneCode = useSelector(selectUserProfilePhoneCode);
        const profilePhoneNumber = useSelector(selectUserProfilePhoneNumber);
        const isLoading = useSelector(selectUserIsLoading);
        const [phoneCode, setPhoneCode] = useState<string>("");
        const {
            control,
            handleSubmit,
            setError,
            clearErrors,
            formState: { errors },
            getValues
        } = useForm<PhoneFormProps>({
            resolver: yupResolver(SetPhoneFormSchema),
            mode: "onChange",
            defaultValues: {
                phoneNumber: profilePhoneNumber?.toString()
            }
        });

        useEffect(() => {
            if (visible) {
                dispatch(fetchCountryCodes());
            }
            if (profilePhoneCode) {
                setPhoneCode(profilePhoneCode);
            }
        }, [visible, profilePhoneCode]);

        const onSubmit = (data: PhoneFormProps): void => {
            dispatch(setUserLoadingStatus(LoadingStatus.NEVER));
            dispatch(updatePhone({ phoneCode, phoneNumber: parseInt(data.phoneNumber), setError }));
        };

        const changeCountryCode = (event: ChangeEvent<{ value: unknown }>): void => {
            setPhoneCode(event.target.value as string);
        };

        if (!visible) {
            return null;
        }

        return (
            <Dialog transitionDuration={0} open={visible} onClose={onClose} className={classes.dialog}>
                <DialogContent className={classes.content}>
                    <div className={classes.logoIcon}>
                        <AppIcon />
                    </div>
                    <div>
                        <Typography variant={"h3"} component={"div"}>
                            {`${isPhoneNumberValid ? "Change Phone" : "Add a phone number"}`}
                        </Typography>
                        <Typography variant={"subtitle1"} component={"div"}>
                            {`${
                                isPhoneNumberValid
                                    ? `Your current phone number is ${profilePhoneCode}${profilePhoneNumber}. What would you like to update it to?`
                                    : "Enter the phone number you’d like to associate with your account."
                            }`}
                        </Typography>
                    </div>
                    <form
                        className={classes.formWrapper}
                        onSubmit={
                            !getValues("phoneNumber") ||
                            !!errors.phoneNumber ||
                            (getValues("phoneNumber") === profilePhoneNumber?.toString() &&
                                phoneCode === profilePhoneCode)
                                ? onClose
                                : handleSubmit(onSubmit)
                        }
                    >
                        <div className={classes.selectWrapper}>
                            <FormControl variant="filled">
                                <InputLabel htmlFor="select-country-code">Country code</InputLabel>
                                <FilledSelect
                                    variant="filled"
                                    labelId="select-country-code"
                                    id="select-country-code"
                                    native
                                    value={phoneCode}
                                    onChange={changeCountryCode}
                                    disabled={isCountryCodesLoading}
                                    label="Country code"
                                    fullWidth
                                >
                                    <option aria-label="None" />
                                    {countryCodes.map((countryCode) => (
                                        <option key={countryCode.id} value={countryCode.phoneCode}>
                                            {countryCode.phoneCode} {countryCode.country}
                                        </option>
                                    ))}
                                </FilledSelect>
                            </FormControl>
                        </div>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue={profilePhoneNumber ? String(profilePhoneNumber) : ""}
                            render={({ field: { onChange, value } }) => (
                                <ChangeInfoTextField
                                    inputMode="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Your phone number"
                                    variant="filled"
                                    onChange={onChange}
                                    value={value}
                                    disabled={isLoading}
                                    helperText={errors.phoneNumber?.message}
                                    error={!!errors.phoneNumber}
                                    fullWidth
                                />
                            )}
                        />
                        {/* <div className={classes.infoWrapper}>
                        <Typography variant={"body1"} component={"span"}>
                              Let people who have your phone number find and connect with you on {APP_NAME}.
                            <MuiLink variant="body1" target="_blank" rel="noopener">
                              Learn more
                            </MuiLink>
                        </Typography>
                        <span><Checkbox /></span>
                    </div> */}
                        <div className={classes.footer}>
                            <Button
                                color="primary"
                                variant={
                                    !getValues("phoneNumber") ||
                                    errors.phoneNumber ||
                                    !phoneCode ||
                                    (getValues("phoneNumber") === profilePhoneNumber?.toString() &&
                                        phoneCode === profilePhoneCode)
                                        ? "outlined"
                                        : "contained"
                                }
                                type="submit"
                                fullWidth
                            >
                                {!getValues("phoneNumber") ||
                                errors.phoneNumber ||
                                !phoneCode ||
                                (getValues("phoneNumber") === profilePhoneNumber?.toString() &&
                                    phoneCode === profilePhoneCode)
                                    ? "Cancel"
                                    : "Next"}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
);

export default ChangePhoneModal;
