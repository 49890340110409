import { ListItem, Typography } from "@material-ui/core";
import { PaperPlaneIcon } from "../../../icons";
import React from "react";
import AppealModal from "./AppealModal/AppealModal";

interface IProps {
    tweetId: string | number;
}

const ApplyForAppeal = ({tweetId}: IProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const onOpenModalWindow = () => {
        setIsModalOpen(true);
    }

    const handleClose = () => {
        setIsModalOpen((prev) => !prev);
    }
    return (
        <>
             <ListItem id={"appeal"} onClick={onOpenModalWindow}>
                <>{PaperPlaneIcon}</>
                <Typography variant={"body1"} component={"span"}>
                    Apply for appeal
                </Typography>
            </ListItem>
            <AppealModal
                isOpen={isModalOpen}
                handleClose={handleClose}
                tweetId={tweetId}
            />
            {/* <TweetComponentActionsModal
                modalTitle={"Pin"}
                isTweetPinned={isTweetPinned}
                visibleTweetComponentActionsModal={visibleModalWindow}
                onCloseTweetComponentActionsModal={onCloseModalWindow}
                onClick={onPinUserTweet}
            /> */}
        </>
    )
}

export default ApplyForAppeal;