import React, { ReactElement } from "react";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { useActionSnackbarStyles } from "./ActionSnackbarStyles";
import {
    selectOpenSnackBar,
    selectSnackBarMessage,
    selectSnackBarType
} from "../../store/ducks/actionSnackbar/selector";
import { setCloseSnackBar } from "../../store/ducks/actionSnackbar/actionCreators";
import classNames from "classnames";
import { ActionSnackbarStateType } from "../../store/ducks/actionSnackbar/contracts/state";

const ActionSnackbar = (): ReactElement => {
    const classes = useActionSnackbarStyles();
    const dispatch = useDispatch();
    const snackBarMessage: any = useSelector(selectSnackBarMessage);
    const openSnackBar = useSelector(selectOpenSnackBar);
    const snackBarType = useSelector(selectSnackBarType);

    const message = React.useMemo(() => {
        let msg = snackBarMessage || "";
        if (snackBarMessage && typeof snackBarMessage == "object") {
            msg = snackBarMessage?.message;
        }
        return msg;
    }, [snackBarMessage]);

    const extraClasses = React.useMemo(() => {
        return snackBarType === ActionSnackbarStateType.WARNING
            ? classes.warningType
            : snackBarType === ActionSnackbarStateType.ERROR
            ? classes.errorMessage
            : "";
    }, [snackBarType]);
    return (
        <Snackbar
            className={classNames(classes.snackBar, extraClasses)}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            open={openSnackBar}
            message={message}
            onClose={() => dispatch(setCloseSnackBar())}
            autoHideDuration={3000}
        />
    );
};

export default ActionSnackbar;
