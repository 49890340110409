import { makeStyles } from "@material-ui/core";

export const usePhoneRestrictionStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    description: {
        padding: "10px",
        textAlign: "center"
    },
    downloadSec: {
        display: "flex",
        gap: "10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    }
}));
