import React, { memo, ReactElement } from "react";
import { useSelector } from "react-redux";

import { useFullListStyles } from "../FullListStyles";
import { selectListItemOwnerWallpaper } from "../../../store/ducks/list/selectors";
import { DEFAULT_LIST_IMG } from '../../../constants/url-constants';

const FullListWallpaper = memo((): ReactElement => {
    const classes = useFullListStyles();
    const listWallpaper = useSelector(selectListItemOwnerWallpaper);
    const wallpaper = listWallpaper ?? DEFAULT_LIST_IMG;

    return (
        <div className={classes.wallpaper}>
            <img key={wallpaper} src={wallpaper} alt={wallpaper} />
        </div>
    );
});

export default FullListWallpaper;
