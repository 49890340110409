export enum ActionSnackbarStateType {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING"
}
export interface ActionSnackbarState {
    snackBarMessage: string;
    openSnackBar: boolean;
    type?: string;
}
