import { memo, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import ImageDescription from "../../../components/ImageDescription/ImageDescription";
import TaggedImageUsers from "../../../components/TaggedImageUsers/TaggedImageUsers";
import { MODAL } from "../../../constants/path-constants";
import {
    selectTweetImageDescription,
    selectTweetImages,
    selectTweetTaggedImageUsers
} from "../../../store/ducks/tweet/selectors";
import { useFullTweetStyles } from "../FullTweetStyles";
import { VIDEO_EXTENSION } from "../../../constants/common-constants";

const TweetImage = memo((): ReactElement => {
    const classes = useFullTweetStyles();
    const params = useParams<{ id: string }>();
    const location = useLocation();
    const images = useSelector(selectTweetImages);
    const imageDescription = useSelector(selectTweetImageDescription);
    const taggedImageUsers = useSelector(selectTweetTaggedImageUsers);

    const getClassName = () => {
        switch (images?.length) {
            case 1:
                return classes["grid-layout-single"];
            case 2:
                return classes["grid-layout-double"];
            case 3:
                return classes["grid-layout-triple"];
            case 4:
                return classes["grid-layout-quad"];
            default:
                return classes["grid-layout-five"];
        }
    };
    const getExtension = (image: any) => {
        return image?.src?.split(".")?.pop();
    };

    return (
        <>
            {images?.length !== 0 && (
                <>
                    <div className={classNames(classes.image, getClassName())}>
                        {images?.map((image, index) => (
                            <>
                                {index > 3 ? <></> : (
                                    <Link
                                        to={{ pathname: `${MODAL}/${params.id}`, state: { background: location } }}
                                        className={classNames(`image${index + 1}`)}
                                        key={index}
                                    >
                                        {VIDEO_EXTENSION.includes(getExtension(image)) ? (
                                            <video controls>
                                                <source src={image?.src} type={`video/${getExtension(image)}`} />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img src={image.src} alt={image.src} />
                                        )}
                                        {index > 2 && (
                                            <div className="imageCountWrapper">
                                                <div className="imageCount">
                                                    +{(images?.length || 0) - 4}
                                                </div>
                                            </div>
                                        )}
                                    </Link>
                                )}
                            </>
                        ))}
                    </div>
                    {imageDescription && <ImageDescription imageDescription={imageDescription} isFullTweet />}
                    {taggedImageUsers && taggedImageUsers.length !== 0 && (
                        <TaggedImageUsers tweetId={params.id} taggedImageUsers={taggedImageUsers} isFullTweet />
                    )}
                </>
            )}
        </>
    );
});

export default TweetImage;
