import {
    Avatar,
    Button,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import React, { FC, memo, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModalWindow } from "../../hook/useModalWindow";
import { ThumbDownIcon, ThumbUpIcon, VeracityIcon } from "../../icons";
import LinkIcon from "@mui/icons-material/Link";
import { fetchVeracityInfo, setVeracityErrorMessage, setVeracityTweetInfoLoadingState } from "../../store/ducks/tweetAdditionalInfo/actionCreators";
import {
    selectVeracityErrorMsg,
    selectVeracityList,
    selectVeracityTweetInfoLoading
} from "../../store/ducks/tweetAdditionalInfo/selectors";
import { LoadingStatus } from "../../types/common";
import { useGlobalStyles } from "../../util/globalClasses";
import ActionIconButton from "../ActionIconButton/ActionIconButton";
import DialogTitleComponent from "../DialogTitleComponent/DialogTitleComponent";
import Spinner from "../Spinner/Spinner";
import { useVeracityModalStyles } from "./VeracityStyles";
import classNames from "classnames";
import { DEFAULT_PROFILE_IMG } from "../../constants/url-constants";
interface VeracityTweetProps {
    tweetId: string;
    text: string;
    userId: string;
    userAvatar?: string;
    userName?: string;
    isFullTweet?: boolean;
}

const VeracityIconButton: FC<VeracityTweetProps> = memo(
    ({ tweetId, text, userId, userAvatar, userName, isFullTweet }): ReactElement => {
        const globalClasses = useGlobalStyles({});
        const classes = useVeracityModalStyles();
        const dispatch = useDispatch();
        const { visibleModalWindow, onOpenModalWindow, onCloseModalWindow } = useModalWindow();
        const veracityList = useSelector(selectVeracityList);
        const veracityTweetInfoLoading = useSelector(selectVeracityTweetInfoLoading);
        const veracityErrorMsg = useSelector(selectVeracityErrorMsg);
        const [selectedTweetId, setSelectedTweetId] = useState<string>("");
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [veracityTweetInfo, setVeracityTweetInfo] = React.useState<any>(null);
        const [reasoning, setReasoning] = React.useState<string[]>([]);
        const [linkIndex, setLinkIndex] = React.useState<number | null>(null);
        const [showReasoning, setShowReasoning] = useState<boolean>(false);
        const [verifyClaim, setVerifyClaim] = useState<string[]>([]);
        const [unVerifyClaim, setUnVerifyClaim] = useState<string[]>([]);
        const [isVerifyClaim, setIsVerifyClaim] = useState<boolean>(false);

        const handleClick = () => {
            setSelectedTweetId(tweetId);
            setVeracityTweetInfo(null);
            setVerifyClaim([]);
            setUnVerifyClaim([]);
            dispatch(
                fetchVeracityInfo({
                    tweet: text,
                    tweet_id: tweetId,
                    userId
                })
            );
            onOpenModalWindow();
        };

        const handlePopoverClick = (event: any, index: number, verified: boolean) => {
            const verifiedReferenceLink: string[] = [];
            const unverifiedReferenceLink: string[] = [];
            setShowReasoning(false);
            // eslint-disable-next-line no-mixed-operators
            const links =
                (veracityTweetInfo?.reference_links?.length && veracityTweetInfo?.reference_links?.[index]) || [];
            veracityTweetInfo?.reference_links.forEach((element: any, index: any) => {
                if (veracityTweetInfo?.verified[index]) {
                    verifiedReferenceLink.push(element);
                } else {
                    unverifiedReferenceLink.push(element);
                }
            });
            setAnchorEl(event.currentTarget);
            setLinkIndex(index);
            setIsVerifyClaim(verified);
            setReasoning(
                verified && verifiedReferenceLink.length > 0
                    ? [verifiedReferenceLink[index]] // Wrap it in an array
                    : unverifiedReferenceLink?.length > 0
                    ? [unverifiedReferenceLink[index]] // Wrap it in an array
                    : [] // Fallback to an empty array
            );
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        useEffect(() => {
            if (selectedTweetId === tweetId && veracityTweetInfoLoading === LoadingStatus.LOADED) {
                setVeracityInfo(tweetId);
            }
        }, [selectedTweetId, onOpenModalWindow, veracityTweetInfoLoading, tweetId]);

        const verifyAndUnverifyClaim = () => {
            const verified: string[] = [];
            const unverified: string[] = [];
            veracityTweetInfo?.claims.forEach((element: any, index: any) => {
                if (veracityTweetInfo?.verified[index]) {
                    verified.push(element);
                } else {
                    unverified.push(element);
                }
            });
            setVerifyClaim(verified);
            setUnVerifyClaim(unverified);
        };

        useEffect(() => {
            if (veracityTweetInfo) {
                verifyAndUnverifyClaim();
                calculationTweetScore();
                calculationUserScore();
            }
        }, [veracityTweetInfo]);

        const setVeracityInfo = (tweetId: string) => {
            const data = veracityList.find((info) => info.tweetId === tweetId);
            if (data) {
                dispatch(setVeracityTweetInfoLoadingState(LoadingStatus.LOADED));
                setVeracityTweetInfo(data);
            }
        };

        const showVeracityInfo = (tweetId: string) => {
            setVeracityInfo(tweetId);
            onOpenModalWindow();
        };

        const onClose = () => {
            dispatch(setVeracityErrorMessage(""));
            setSelectedTweetId("");
            onCloseModalWindow();
        };

        const setItemIcon = (index: number) => {
            if (veracityTweetInfo?.verified[index]) {
                return (
                    <ListItemIcon>
                        <CheckIcon fontSize="small" style={{ color: "rgb(82, 181, 251)" }} />
                    </ListItemIcon>
                );
            }
            return (
                <ListItemIcon>
                    <ClearIcon fontSize="small" style={{ color: "red" }} />
                </ListItemIcon>
            );
        };

        const calculationTweetScore = () => {
            if (veracityTweetInfo?.tweet_score) {
                return Math.floor(veracityTweetInfo?.tweet_score);
            }
            return 0;
        };

        const calculationUserScore = () => {
            if (veracityTweetInfo?.userScore) {
                return Math.floor(veracityTweetInfo?.userScore);
            }
            return 0;
        };

        const getScoreLabel = () => {
            const score = calculationTweetScore();
            if (score >= 80) {
                return "This post can be trusted";
            } else if (score >= 50) {
                return "This post can be somewhat trusted";
            } else {
                return "This post cannot be trusted";
            }
        };

        const getUserScoreLabel = () => {
            const score = calculationUserScore();
            if (score >= 80) {
                return {
                    text: "This user can typically be trusted",
                    label: "Trusted"
                };
            } else if (score >= 50) {
                return {
                    text: "This user can typically be somewhat trusted",
                    label: "Somewhat Trusted"
                };
            } else {
                return {
                    text: "This user cannot typically be trusted",
                    label: "Not Trusted"
                };
            }
        };

        const getColor = (score: number) => {
            if (score >= 80) {
                return "#24BB10";
            } else if (score >= 50) {
                return "#EDA31A";
            } else {
                return "#EC1111";
            }
        };

        const clickReason = (event: any) => {
            setShowReasoning(true);
            setAnchorEl(event.currentTarget);
            setReasoning(veracityTweetInfo?.reasoning);
        };

        const getTweetScore = (tweetId: string) => {
            const data = veracityList.find((info) => info.tweetId === tweetId);
            return data ? data.tweet_score : null;
        };

        const VeracityBtnIcon = ({ tweetId, isFullTweet }: { tweetId: string; isFullTweet?: boolean }) => {
            const tweetScore = useMemo(() => getTweetScore(tweetId), [tweetId]);

            const handleIconClick = useCallback(() => {
                showVeracityInfo(tweetId);
            }, [tweetId]);

            if (tweetScore === null) {
                return (
                    <div className={isFullTweet ? classes.fullVeracityIcon : ""}>
                        <ActionIconButton
                            actionText={"Veracity"}
                            onClick={handleClick}
                            size={"small"}
                            icon={VeracityIcon}
                        />
                    </div>
                );
            }

            return (
                <span className={classes.tweetScore} onClick={handleIconClick}>
                    <span style={{ backgroundColor: getColor(tweetScore) }}></span>
                    {tweetScore}
                </span>
            );
        };

        const open = Boolean(anchorEl);

        return (
            <>
                <div className={classes.root}>
                    <VeracityBtnIcon tweetId={tweetId} isFullTweet={isFullTweet} />
                </div>
                <Dialog className={classes.content} open={visibleModalWindow} onClose={onClose}>
                    <DialogTitleComponent title={"Post Report"} onClose={onClose} />
                    <DialogContent className={classes.dialogContent}>
                        {veracityTweetInfoLoading === LoadingStatus.LOADING && <Spinner paddingTop={90} />}
                        {veracityTweetInfoLoading === LoadingStatus.LOADED && (
                            <>
                                <div className={classes.postScoreHeaderWrapper} style={{ position: "relative" }}>
                                    <Paper className={classes.container}>
                                        <div
                                            className={classNames(classes.postCounter, classes.headerPostCounter)}
                                            style={{ backgroundColor: getColor(calculationTweetScore()), width: 55 }}
                                        >
                                            <span>{calculationTweetScore()}</span>
                                        </div>
                                        <div className={classes.postReport}>
                                            <Typography variant={"h3"}>{getScoreLabel()}</Typography>
                                        </div>
                                    </Paper>
                                    <List className={classes.postReportList}>
                                        {verifyClaim?.length > 0 && (
                                            <ListItem style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography
                                                        variant={"body1"}
                                                        className={classes.postCounter}
                                                        style={{ backgroundColor: "#29A818" }}
                                                    >
                                                        {ThumbUpIcon}
                                                        {/* <ThumbUpOffAltIcon /> */}
                                                    </Typography>
                                                    <div className={classes.heading}>Claim That Appeer True</div>
                                                </div>
                                                <ul className={classes.referenceLinkList}>
                                                    {verifyClaim.map((item: string, index: number) => (
                                                        <li className={classes.listItem} key={index}>
                                                            <Typography variant={"h6"} style={{ fontWeight: 400 }}>
                                                                {item}
                                                                <span
                                                                    className={classes.referenceLink}
                                                                    onClick={(event) =>
                                                                        handlePopoverClick(event, index, true)
                                                                    }
                                                                    style={{ marginLeft: "2px", color: "#3396ff" }}
                                                                >
                                                                    Reference
                                                                </span>
                                                            </Typography>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </ListItem>
                                        )}
                                        {unVerifyClaim?.length > 0 && (
                                            <ListItem style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography
                                                        variant={"body1"}
                                                        className={classes.postCounter}
                                                        style={{ backgroundColor: "#F00C0C" }}
                                                    >
                                                        {ThumbDownIcon}
                                                        {/* <ThumbUpOffAltIcon sx={{ transform: "rotate(-180deg)" }} /> */}
                                                    </Typography>
                                                    <div className={classes.heading}>Claim That Appeer False</div>
                                                </div>
                                                <ul className={classes.referenceLinkList}>
                                                    {unVerifyClaim.map((item: string, index: number) => (
                                                        <li className={classes.listItem} key={index}>
                                                            <Typography variant={"h6"} style={{ fontWeight: 400 }}>
                                                                {item}
                                                                <span
                                                                    className={classes.referenceLink}
                                                                    onClick={(event) =>
                                                                        handlePopoverClick(event, index, false)
                                                                    }
                                                                    style={{ marginLeft: "2px", color: "#3396ff" }}
                                                                >
                                                                    Reference
                                                                </span>
                                                            </Typography>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </ListItem>
                                        )}
                                    </List>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                    {veracityTweetInfo?.reasoning?.length > 0 && calculationTweetScore() !== 0 && (
                                        <Button
                                            onClick={clickReason}
                                            style={{ margin: "0 auto", width: "max-content" }}
                                            className={classes.reportDetailsBtn}
                                        >
                                            Report Details
                                        </Button>
                                    )}
                                </div>
                                <Divider className={classes.divider} />
                                <Paper className={classes.userContainer}>
                                    <div className={classes.userCountReport}>
                                        <Typography
                                            variant={"body1"}
                                            className={classes.userScoreCount}
                                            style={{ backgroundColor: getColor(calculationUserScore()) }}
                                        >
                                            {calculationUserScore()}
                                        </Typography>
                                        <Typography variant={"h3"}>{getUserScoreLabel().text}</Typography>
                                    </div>
                                    <div className={classes.userProfileReport}>
                                        <Typography variant={"body1"} className={classes.userAvatar}>
                                            <Avatar
                                                className={globalClasses.avatar}
                                                src={userAvatar ?? DEFAULT_PROFILE_IMG}
                                                alt={`avatar ${userId!}`}
                                            />
                                        </Typography>
                                        <Typography variant={"h6"}>
                                            {getUserScoreLabel().label}: This tweet appears unreliable and may contain
                                            false or misleading information. It does not match trusted sources and
                                            should be approached with caution.
                                        </Typography>
                                    </div>
                                    <div className={classes.stepperline}></div>
                                </Paper>
                                <Dialog
                                    onClose={handleClose}
                                    open={open}
                                    className={classes.reportReferenceDetailModal}
                                >
                                    <DialogTitleComponent title={"Post report details"} onClose={handleClose} />
                                    <DialogContent>
                                        <DialogContentText>
                                            {showReasoning && (
                                                <Typography
                                                    variant={"h6"}
                                                    style={{ marginLeft: "4px", fontWeight: 400, color: "#3396ff" }}
                                                >
                                                    Post details
                                                </Typography>
                                            )}
                                            <div className={classes.referenceLinkWrapper}>
                                                <Paper className={classes.userContainer}>
                                                    <div>
                                                        <div className={classes.userProfileReport}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography
                                                                    variant={"body1"}
                                                                    className={classes.userAvatar}
                                                                >
                                                                    <Avatar
                                                                        className={globalClasses.avatar}
                                                                        src={userAvatar ?? DEFAULT_PROFILE_IMG}
                                                                        alt={`avatar ${userId!}`}
                                                                    />
                                                                </Typography>
                                                                <Typography
                                                                    variant={"h3"}
                                                                    style={{ marginLeft: "8px" }}
                                                                >
                                                                    {userName}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <Typography
                                                            className={classes.typography}
                                                            style={{ padding: "0 0 0 60px" }}
                                                        >
                                                            {text}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.referenceStepperline}></div>
                                                    <Paper className={classes.container}>
                                                        <div
                                                            className={classes.postCounter}
                                                            style={{
                                                                backgroundColor: getColor(calculationTweetScore())
                                                            }}
                                                        >
                                                            <span>{calculationTweetScore()}</span>
                                                        </div>
                                                        <div className={classes.postReport}>
                                                            <Typography variant={"h3"}>{getScoreLabel()}</Typography>
                                                            <List className={classes.postReportList}>
                                                                <ListItem>
                                                                    <Typography
                                                                        variant={"h6"}
                                                                        style={{ marginLeft: "4px", fontWeight: 400 }}
                                                                    >
                                                                        Trusted: This tweet appears unreliable and may
                                                                        contain false or misleading information. It does
                                                                        not match trusted sources and should be
                                                                        approached with caution.
                                                                    </Typography>
                                                                </ListItem>
                                                            </List>
                                                        </div>
                                                    </Paper>
                                                </Paper>
                                                <Divider className={classes.divider} />
                                                {!showReasoning && (
                                                    <>
                                                        <div className={classes.referenceLinkHeader}>
                                                            <div
                                                                className={classes.thumbWrapper}
                                                                style={{
                                                                    backgroundColor: isVerifyClaim
                                                                        ? "#29A818"
                                                                        : "#F00C0C"
                                                                }}
                                                            >
                                                                {
                                                                    isVerifyClaim
                                                                        ? // <ThumbUpOffAltIcon />
                                                                          ThumbUpIcon
                                                                        : ThumbDownIcon
                                                                    // <ThumbUpOffAltIcon
                                                                    //     sx={{ transform: "rotate(-180deg)" }}
                                                                    // />
                                                                }
                                                            </div>
                                                            <div className={classes.heading}>
                                                                Claims That Appeer {isVerifyClaim ? "True" : "False"}
                                                            </div>
                                                        </div>
                                                        <div className={classes.subHeading}>
                                                            {linkIndex !== null && veracityTweetInfo?.claims[linkIndex]}
                                                        </div>
                                                    </>
                                                )}
                                                <div className={classes.referenceLinkInner}>
                                                    {showReasoning && (
                                                        <Typography
                                                            variant={"h6"}
                                                            style={{ fontWeight: 400, color: "#3396ff", marginTop: 10 }}
                                                        >
                                                            Details
                                                        </Typography>
                                                    )}
                                                    {reasoning.map((link, index) => (
                                                        <>
                                                            {showReasoning ? (
                                                                <div className={classes.claimWrapper}>
                                                                    <div className={classes.claimHeading}>
                                                                        Claim {index + 1}
                                                                    </div>
                                                                    <Typography
                                                                        className={classes.typography}
                                                                        style={{ paddingInline: "0px" }}
                                                                    >
                                                                        {link}
                                                                    </Typography>
                                                                </div>
                                                            ) : (
                                                                <div style={{ marginLeft: "50px" }}>
                                                                    {Array.isArray(link) ? (
                                                                        link.map((l) => (
                                                                            <Typography
                                                                                className={classes.typography}
                                                                                key={index + link}
                                                                            >
                                                                                {/* {`${index + 1}. `} */}
                                                                                <a
                                                                                    className={
                                                                                        classes.redirectReferenceLink
                                                                                    }
                                                                                    target="_blank"
                                                                                    href={l}
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <LinkIcon
                                                                                        sx={{
                                                                                            transform: "rotate(-45deg)"
                                                                                        }}
                                                                                    />{" "}
                                                                                    {l}
                                                                                </a>
                                                                            </Typography>
                                                                        ))
                                                                    ) : (
                                                                        <Typography
                                                                            className={classes.typography}
                                                                            key={index}
                                                                        >
                                                                            {/* {`${index + 1}. `} */}
                                                                            <a
                                                                                className={
                                                                                    classes.redirectReferenceLink
                                                                                }
                                                                                target="_blank"
                                                                                href={link}
                                                                                rel="noreferrer"
                                                                            >
                                                                                <LinkIcon
                                                                                    sx={{
                                                                                        transform: "rotate(-45deg)"
                                                                                    }}
                                                                                />{" "}
                                                                                {link}
                                                                            </a>
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            </>
                        )}
                        {veracityTweetInfoLoading === LoadingStatus.ERROR && (
                            <p className={classes.errorMsg}>{veracityErrorMsg}</p>
                        )}
                    </DialogContent>
                </Dialog>
            </>
        );
    }
);

export default VeracityIconButton;
