import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import classNames from "classnames";
import { CompatClient, Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import TweetComponent from "../TweetComponent/TweetComponent";
import { useTweetImageStyles } from "./TweetImageModalStyles";
import {
    fetchReplies,
    fetchTweetData,
    resetRepliesState,
    resetTweetState,
    updateTweetData
} from "../../store/ducks/tweet/actionCreators";
import {
    selectIsRepliesLoading,
    selectIsTweetLoadedSuccess,
    selectReplies,
    selectTweetId,
    selectTweetImages
} from "../../store/ducks/tweet/selectors";
import { WS_URL } from "../../constants/endpoint-constants";
import ShareTweetIconButton from "../ShareTweetIconButton/ShareTweetIconButton";
import Spinner from "../Spinner/Spinner";
import TweetHeader from "./TweetHeader/TweetHeader";
import TweetText from "./TweetText/TweetText";
import TweetDate from "./TweetDate/TweetDate";
import TweetInteractionCount from "./TweetInteractionCount/TweetInteractionCount";
import TweetReplyIconButton from "./TweetReplyIconButton/TweetReplyIconButton";
import TweetRetweetedIconButton from "./TweetRetweetedIconButton/TweetRetweetedIconButton";
import TweetLikeIconButton from "./TweetLikeIconButton/TweetLikeIconButton";
import AddReplyToTweet from "./AddReplyToTweet/AddReplyToTweet";
import ImageFooterReplyIconButton from "./ImageFooterReplyIconButton/ImageFooterReplyIconButton";
import ImageFooterRetweetButton from "./ImageFooterRetweetButton/ImageFooterRetweetButton";
import ImageFooterLikeButton from "./ImageFooterLikeButton/ImageFooterLikeButton";
import ImageFooterShareButton from "./ImageFooterShareButton/ImageFooterShareButton";
import ImageCloseButton from "./ImageCloseButton/ImageCloseButton";
import { TOPIC_TWEET } from "../../constants/ws-constants";
import { VIDEO_EXTENSION } from "../../constants/common-constants";
import { selectDeclinedTweetState } from "../../store/ducks/tweets/selectors";

let stompClient: CompatClient | null = null;
// Define the type for location.state
interface ModalState {
    imageIndex?: number;
}

const TweetImageModal = (): ReactElement | null => {
    const dispatch = useDispatch();
    const tweetId = useSelector(selectTweetId);
    const images = useSelector(selectTweetImages);
    const replies = useSelector(selectReplies);
    const isTweetLoadedSuccess = useSelector(selectIsTweetLoadedSuccess);
    const isRepliesLoading = useSelector(selectIsRepliesLoading);
    const declinedTweets = useSelector(selectDeclinedTweetState);
    const params = useParams<{ id: string }>();
    const history = useHistory();
    const location = useLocation<ModalState>();
    const [visibleTweetImageModalWindow, setVisibleTweetImageModalWindow] = useState<boolean>(false);
    const [current, setCurrent] = useState<number>(0);
    const [hideRightArrow, setHideRightArrow] = useState<boolean>(false);
    const [hideLeftArrow, setHideLeftArrow] = useState<boolean>(false);
    const [isDeclined, setIsDeclined] = useState<boolean>(false);
    
    const classes = useTweetImageStyles({ isDeclined });

    useEffect(() => {
        if (current >= 0 && images?.length && images?.length > 1) {
            setHideLeftArrow(false);
            setHideRightArrow(false);
            if (images?.length === 1) {
                setHideLeftArrow(true);
                setHideRightArrow(true);
            } else if (current === 0) {
                setHideLeftArrow(true);
            } else if (current + 1 === images?.length) {
                setHideRightArrow(true);
            }
        } else {
            setHideLeftArrow(true);
            setHideRightArrow(true);
        }
    }, [current, images?.length]);

    useEffect(() => {
        if (location?.state?.imageIndex !== undefined) {
            setCurrent(location.state.imageIndex);
        }
        dispatch(fetchTweetData(params.id));
        setVisibleTweetImageModalWindow(true);
        document.body.style.marginRight = "15px";
        document.body.style.overflow = "hidden";

        stompClient = Stomp.over(new SockJS(WS_URL));
        stompClient.connect({}, () => {
            stompClient?.subscribe(TOPIC_TWEET(params.id), (response) => {
                dispatch(updateTweetData(JSON.parse(response.body)));
            });
        });

        return () => {
            // stompClient?.disconnect();
            dispatch(resetTweetState());
        };
    }, []);

    useEffect(() => {
        dispatch(fetchReplies(params.id));

        return () => {
            dispatch(resetRepliesState());
        };
    }, [isTweetLoadedSuccess]);

    useEffect(() => {
        if (tweetId) {
            const declinedTweet = declinedTweets?.findIndex((declinedTweet: any) => declinedTweet.id == tweetId);
            if (declinedTweet !== -1) {
                setIsDeclined(true);
            } else {
                setIsDeclined(false);
            }
        }
    }, [tweetId, declinedTweets]);

    const onCloseImageModalWindow = (event: any): void => {
        if (event.target.classList[0]) {
            if (event.target.classList[0].includes("container")) {
                onClose();
            }
        }
    };

    const onCloseModalWindow = useCallback((): void => {
        onClose();
    }, []);

    const onClose = (): void => {
        setCurrent(0);
        setVisibleTweetImageModalWindow(false);
        document.body.style.marginRight = "0px";
        document.body.style.overflow = "unset";
        history.goBack();
    };

    if (!visibleTweetImageModalWindow) {
        return null;
    }

    const nextSlide = () => {
        if (images) {
            setCurrent(current === images.length - 1 ? 0 : current + 1);
        }
    };

    const prevSlide = () => {
        if (images) {
            setCurrent(current === 0 ? images.length - 1 : current - 1);
        }
    };

    const getExtension = (image: any) => {
        return image?.src?.split(".")?.pop();
    };

    if (tweetId) {
        return (
            <div className={classes.container} onClick={onCloseImageModalWindow}>
                <div className={classes.containerInner}>
                    <div className={classes.closeWrapper}>
                        <ImageCloseButton onCloseModalWindow={onCloseModalWindow} />
                    </div>
                    <div className={classes.preViewWrapper}>
                        <div className={classes.preViewInner}>
                            <div className={classes.carouselWrapper}>
                                <div className={classes.arrowWrapper}>
                                    {!hideLeftArrow && (
                                        <button onClick={prevSlide} className={classes.arrowPrev}>
                                            <WestIcon />
                                        </button>
                                    )}
                                </div>
                                <div className={classes.imgWrapper}>
                                    {VIDEO_EXTENSION.includes(getExtension(images?.[current])) ? (
                                        <video key={current} controls autoPlay loop>
                                            <source
                                                src={images?.[current]?.src}
                                                type={`video/${getExtension(images?.[current])}`}
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img
                                            className={classes.imageModal}
                                            src={(images?.[current]?.src as unknown as string) || ""}
                                            alt={`Slide ${current + 1}`}
                                        />
                                    )}
                                </div>
                                <div className={classes.arrowWrapper}>
                                    {!hideRightArrow && (
                                        <button onClick={nextSlide} className={classes.arrowNext}>
                                            <EastIcon />
                                        </button>
                                    )}
                                </div>
                            </div>
                            {!isDeclined && (
                                <div id={"imageFooter"} className={classes.imageFooterContainer}>
                                    <div className={classNames(classes.imageFooterWrapper)}>
                                        <ImageFooterReplyIconButton />
                                        <ImageFooterRetweetButton />
                                        <ImageFooterLikeButton />
                                        <ImageFooterShareButton />
                                    </div>
                                </div>
                            )}
                            
                        </div>
                    </div>
                    {!isDeclined && (
                        <div className={classes.modalWrapper}>
                            <div className={classes.tweetInfo}>
                                <TweetHeader />
                                <TweetText />
                                <TweetDate />
                                <Divider />
                                <TweetInteractionCount />
                                <div id={"tweetFooter"} className={classes.tweetFooter}>
                                    <TweetReplyIconButton />
                                    <TweetRetweetedIconButton />
                                    <TweetLikeIconButton />
                                    <ShareTweetIconButton tweetId={tweetId} isFullTweet={false} />
                                </div>
                                <Divider />
                                <AddReplyToTweet />
                            </div>
                            <Divider />
                            {isRepliesLoading ? (
                                <Spinner />
                            ) : (
                                replies.map((tweet) => <TweetComponent key={tweet.id} tweet={tweet} isTweetImageModal />)
                            )}
                        </div>
                    )}
                    
                </div>
            </div>
        );
    }
    return null;
};

export default TweetImageModal;
