import React, { FC, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

import { useGlobalStyles } from "../../util/globalClasses";

interface LinkWrapperProps {
    children: ReactNode;
    path: string;
    visiblePopperWindow?: boolean;
    disableClick?: boolean;
    id?: string;
}

const LinkWrapper: FC<LinkWrapperProps> = ({ children, path, visiblePopperWindow, disableClick, id }): ReactElement => {
    const globalClasses = useGlobalStyles({});

    if (visiblePopperWindow) {
        return <span id={id}>{children}</span>;
    } else {
        return disableClick ? (
            <span className={globalClasses.link}>{children}</span> // Non-clickable wrapper
        ) : (
            <Link id={id} to={path} className={globalClasses.link}>
                {children}
            </Link>
        );
    }
};

export default LinkWrapper;
