import { call, put, takeLatest } from 'redux-saga/effects';
import { setOpenSnackBar } from '../actionSnackbar/actionCreators';
import { LaunchSoonActionTypes, ProcessPreProcessRegistrationActionInterface } from './contracts/actionTypes';
import { LaunchApi } from '../../../services/api/launch-service/launchApi';
import { setCommonStatus } from '../common/actionCreators';
import { commonInitstatus } from '../common/contracts/state';

export function* processPreRegistration({payload}: ProcessPreProcessRegistrationActionInterface) {
  try {
        yield put(setCommonStatus({...commonInitstatus, isLoading: true}));
        yield call(LaunchApi.processPreRegistration, payload);
        yield put(setCommonStatus({...commonInitstatus, isSuccess: true, isLoading: false}));
    } catch (error) {
        yield put(setCommonStatus({...commonInitstatus, isFail: true, isLoading: false}));
        if (error?.response?.data?.message) {
          yield put(setOpenSnackBar({
            message: error?.response?.data?.message
          }))
        } else {
          yield put(setOpenSnackBar({
            message: error?.message
          }))
        }
    }
}

export function* launchSoonSaga() {
    yield takeLatest(LaunchSoonActionTypes.PROCESS_PRE_PROCESS_REGISTRATION, processPreRegistration);
}