import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";

import { useFullListStyles } from "./FullListStyles";
import {
    selectIsListLoaded,
    selectIsListLoading,
    selectListErrorData,
    selectListItem,
    selectListItemId,
    selectListItemIsFollower,
    selectListItemName,
    selectListItemOwnerId,
    selectListItemOwnerUsername,
    selectListState
} from "../../store/ducks/list/selectors";
import { fetchListById, resetListState } from "../../store/ducks/list/actionCreators";
import { selectUserDataId } from "../../store/ducks/user/selectors";
import Spinner from "../../components/Spinner/Spinner";
import { useGlobalStyles } from "../../util/globalClasses";
import FollowListButton from "../../components/FollowListButton/FollowListButton";
import FullListTweets from "./FullListTweets/FullListTweets";
import MembersAndFollowers from "./MembersAndFollowers/MembersAndFollowers";
import EditListButton from "./EditListButton/EditListButton";
import FullListHeader from "./FullListHeader/FullListHeader";
import FullListWallpaper from "./FullListWallpaper/FullListWallpaper";
import FullListDescription from "./FullListDescription/FullListDescription";
import { APP_NAME } from '../../constants/common-constants';
import classNames from "classnames";
import { setOpenSnackBar } from "../../store/ducks/actionSnackbar/actionCreators";
import { ActionSnackbarStateType } from "../../store/ducks/actionSnackbar/contracts/state";

const FullList: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useFullListStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);
    const listId = useSelector(selectListItemId);
    const listName = useSelector(selectListItemName);
    const listErrorData = useSelector(selectListErrorData);
    const listIsFollower = useSelector(selectListItemIsFollower);
    const listOwnerId = useSelector(selectListItemOwnerId);
    const listOwnerUsername = useSelector(selectListItemOwnerUsername);
    const isListLoading = useSelector(selectIsListLoading);
    const isListLoaded = useSelector(selectIsListLoaded);
    const params = useParams<{ listId: string }>();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchListById(params.listId));

        return () => {
            dispatch(resetListState());
        };
    }, [params.listId]);

    useEffect(() => {
        if (isListLoaded) {
            document.title = `@${listOwnerUsername}/${listName} | ${APP_NAME}`;
        }
    }, [isListLoaded]);

    useEffect(() => {
        if (listErrorData?.message?.includes("blocked")) {
            dispatch(setOpenSnackBar({
                message: "Unauthorized list",
                type: ActionSnackbarStateType.ERROR
            }))
            history.goBack();
        }
    }, [listErrorData]);

    if (!isListLoading && !listName && !listId) {
        return (
            <Paper className={globalClasses.pageContainer} variant="outlined">
                <div className={classNames(globalClasses.contentWrapper, classes.notFoundList)}>
                    <Typography variant="h3">List has been deleted</Typography>
                </div>
            </Paper>
        )
    }

    return (
        <Paper className={globalClasses.pageContainer} variant="outlined">
            <FullListHeader />
            <div className={globalClasses.contentWrapper}>
                {isListLoading ? (
                    <Spinner paddingTop={250} />
                ) : (
                    <>
                        <FullListWallpaper />
                        <Paper className={classes.listInfo} variant="outlined">
                            <FullListDescription />
                            <MembersAndFollowers />
                            <div className={classes.buttonWrapper}>
                                {(myProfileId === listOwnerId) ? (
                                    <EditListButton />
                                ) : (
                                    <FollowListButton listId={listId!} isFollower={listIsFollower!} />
                                )}
                            </div>
                        </Paper>
                    </>
                )}
                <FullListTweets />
            </div>
        </Paper>
    );
};

export default FullList;
