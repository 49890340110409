import format from "date-fns/format";
import usLang from "date-fns/locale/en-US/index";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { PollResponse } from "../types/tweet";
import { isBefore, isToday, subWeeks } from "date-fns";

export const formatDate = (date: Date): string => {
    return format(date, "MMM d");
};

export const formatNewsPublishedDate = (date: Date): string => {
    return format(date, "PP");
};

export const formatScheduleDate = (date: Date): string => {
    return format(date, "EEE, MMM d, yyyy 'at' hh:mm a");
};

export const formatChatMessageDate = (date: Date): string => {
    const currentDate = new Date();
    const oneWeekAgo = subWeeks(currentDate, 1);
    // If the message was sent today, show "Today" with the time
    if (isToday(date)) {
        return `Today ${format(date, "hh:mm a", { locale: usLang })}`;
    }
    // If the date is older than 1 week, format with full date
    if (isBefore(date, oneWeekAgo)) {
        return format(date, "MM/dd/yyyy hh:mm a", { locale: usLang });
    }
    // Otherwise, format with the day of the week and time
    return format(date, "EEE hh:mm a", { locale: usLang });
};

export const voteFormatDate = (poll: PollResponse): string => {
    const diffInDays = differenceInDays(new Date(poll?.createdAt!), Date.now());
    const diffInHours = differenceInHours(new Date(poll?.createdAt!), Date.now());
    const diffInMinutes = differenceInMinutes(new Date(poll?.createdAt!), Date.now());

    if (diffInDays !== 0) {
        return diffInDays + " days";
    } else if (diffInHours !== 0) {
        return diffInHours + " hours";
    } else {
        return diffInMinutes + " minutes";
    }
};