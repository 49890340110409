import React, { FC, memo, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { useRetweetIconButtonStyles } from "./RetweetIconButtonStyles";
import { selectIsTweetRetweeted } from "../../../store/ducks/tweet/selectors";
import { RetweetIcon, RetweetOutlinedIcon } from "../../../icons";
import { useGlobalStyles } from "../../../util/globalClasses";
import { retweet } from "../../../store/ducks/tweets/actionCreators";
import ActionIconButton from "../../../components/ActionIconButton/ActionIconButton";
import { selectUserDataId } from '../../../store/ducks/user/selectors';
import classNames from "classnames";
interface RetweetIconButtonProps {
  retweetsCount?: number;
}

const RetweetIconButton: FC<RetweetIconButtonProps> = memo(({ retweetsCount }): ReactElement => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const isTweetRetweeted = useSelector(selectIsTweetRetweeted);
    const myProfileId = useSelector(selectUserDataId);
    const globalClasses = useGlobalStyles({});
    const classes = useRetweetIconButtonStyles({ isTweetRetweeted: isTweetRetweeted! });

    const [isRetweet, setIsRetweet] = React.useState(isTweetRetweeted);
    const [count, setCount] = React.useState<number>(retweetsCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);

    const handleCounts = () => {
        setIsRetweet(!isRetweet);
        setCount((prevState) => isRetweet ? --prevState : ++prevState);
        setAnimationLike(isRetweet ? globalClasses.countGoDown : globalClasses.countGoUp);
        setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
    }

    const handleRetweet = (): void => {
        handleCounts()
        dispatch(retweet({ tweetId: params.id, userId: myProfileId }));
    };

    // Update like counts on props changes
    React.useEffect(() => {
        if (retweetsCount !== undefined && retweetsCount !== count) {
            setCount(retweetsCount);
            setAnimationLike(retweetsCount < count ? globalClasses.countGoDown : globalClasses.countGoUp);
            setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        }
    }, [retweetsCount]);

    return (
        <div className={classnames(globalClasses.svgLarge, classes.retweetIcon)}>
            <ActionIconButton
                actionText={isRetweet ? "Undo Retweet" : "Retweet"}
                onClick={handleRetweet}
                icon={isRetweet ? RetweetIcon : RetweetOutlinedIcon}
            />
            {(count !== 0) && (
              <span id={"retweets"} className={classNames(classes.retweetsCount, animationLike)}>
                {count}
              </span>
            )}
        </div>
    );
});

export default RetweetIconButton;
