import { Avatar, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { FC, memo, ReactElement, useMemo } from "react";

import LinkWrapper from "../../../../components/LinkWrapper/LinkWrapper";
import PopperUserWindow from "../../../../components/PopperUserWindow/PopperUserWindow";
import { DECLINED_TWEETS, LISTS, NOTIFICATION, PROFILE } from "../../../../constants/path-constants";
import { DEFAULT_PROFILE_IMG } from "../../../../constants/url-constants";
import { HoverItemDetail, useHoverItem } from "../../../../hook/useHoverItem";
import {
    LikeIcon,
    ListsIconFilled,
    ProfileIconFilled,
    RejectIcon,
    ReplyIconFilled,
    RetweetIcon,
    TweetPostIcon
} from "../../../../icons";
import { fetchUserDetail } from "../../../../store/ducks/userDetail/actionCreators";
import { NotificationType } from "../../../../types/common";
import { NotificationResponse } from "../../../../types/notification";
import { textFormatter } from "../../../../util/text-formatter";
import { useNotificationItemStyles } from "./NotificationItemStyles";

export interface NotificationItemProps {
    notification: NotificationResponse;
}

const NotificationItem: FC<NotificationItemProps> = memo(({ notification }): ReactElement => {
    const classes = useNotificationItemStyles();
    const { visiblePopperWindow, handleHoverPopper, handleLeavePopper } = useHoverItem(fetchUserDetail);
    const avatar = notification.user.avatar ?? DEFAULT_PROFILE_IMG;

    // Generate PATH
    const path = useMemo(() => {
        if (notification.notificationType === NotificationType.FOLLOW) return `${PROFILE}/${notification.user.id}`;
        if (notification.notificationType === NotificationType.LISTS) return `${LISTS}/${notification.list.id}`;
        if (notification.notificationType === NotificationType.REJECT) return `${DECLINED_TWEETS}`;
        return `${NOTIFICATION}/${notification.id}`;
    }, [])

    return (
        <LinkWrapper
            path={path}
            // disableClick={notification.notificationType === NotificationType.REJECT}
            visiblePopperWindow={visiblePopperWindow}
        >
            <Paper className={classes.notificationWrapper} variant="outlined">
                <div className={classes.notificationIcon}>
                    {notification.notificationType === NotificationType.LIKE && <span id={"like"}>{LikeIcon}</span>}
                    {notification.notificationType === NotificationType.RETWEET && (
                        <span id={"retweet"}>{RetweetIcon}</span>
                    )}
                    {notification.notificationType === NotificationType.FOLLOW && (
                        <span id={"follow"}>{ProfileIconFilled}</span>
                    )}
                    {notification.notificationType === NotificationType.LISTS && (
                        <span id={"list"}>{ListsIconFilled}</span>
                    )}
                    {notification.notificationType === NotificationType.REPLY && (
                        <span id={"reply"}>{ReplyIconFilled}</span>
                    )}
                    {notification.notificationType === NotificationType.POST && (
                        <span id={"post"}>{TweetPostIcon}</span>
                    )}
                    {notification.notificationType === NotificationType.REJECT && (
                        <span id={"reject"}>{RejectIcon}</span>
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <LinkWrapper path={`${PROFILE}/${notification.user.id!}`} visiblePopperWindow={visiblePopperWindow}>
                        <div
                            id={"userInfo"}
                            onMouseEnter={() => handleHoverPopper({ userId: notification.user.id } as HoverItemDetail)}
                            onMouseLeave={handleLeavePopper}
                        >
                            <Avatar className={classes.notificationAvatar} src={avatar} alt={avatar} />
                            <PopperUserWindow visible={visiblePopperWindow} />
                        </div>
                    </LinkWrapper>
                    <div className={classes.notificationInfo}>
                        {notification.notificationType !== NotificationType.POST &&
                            notification.notificationType !== NotificationType.REJECT && (
                                <Typography variant={"h6"} component={"span"}>
                                    {`${notification.user.username} `}
                                </Typography>
                            )}
                        <Typography variant={"body1"} component={"span"}>
                            {notification.notificationType === NotificationType.FOLLOW ? (
                                <>followed you</>
                            ) : notification.notificationType === NotificationType.LISTS ? (
                                <>
                                    {"added you to their List "}
                                    <Typography variant={"h6"} component={"span"}>
                                        {notification.list.name}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    {notification.notificationType === NotificationType.LIKE
                                        ? "liked"
                                        : notification.notificationType === NotificationType.RETWEET
                                        ? "Retweeted"
                                        : notification.notificationType === NotificationType.REPLY
                                        ? "replied to"
                                        : null}{" "}
                                    Your Tweet{" "}
                                    {notification.notificationType === NotificationType.REJECT && "has been rejected."}
                                    {notification.notificationType === NotificationType.POST && "has been approved."}
                                </>
                            )}
                        </Typography>
                    </div>
                    <Typography variant={"body1"} component={"div"} className={classes.notificationText}>
                        {notification.tweet && textFormatter(notification.tweet.text)}
                    </Typography>
                </div>
            </Paper>
        </LinkWrapper>
    );
});

export default NotificationItem;
