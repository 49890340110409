import { makeStyles } from "@material-ui/core";

export const useActionSnackbarStyles = makeStyles((theme) => ({
    snackBar: {
        "& .MuiPaper-root": {
            border: "none"
        },
        "& .MuiSnackbarContent-root": {
            color: theme.palette.common.white,
            width: "80px",
            height: "auto",
            padding: "0px 15px",
            backgroundColor: theme.palette.primary.main,
            "& .MuiSnackbarContent-message": {
                fontSize: 15,
                margin: "0 auto",
                textAlign: "center"
            }
        }
    },
    warningType: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.warning.main
        }
    },
    errorMessage: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.error.main
        }
    }
}));
