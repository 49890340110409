import React, { FC, memo, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ActionIconButton from "../../ActionIconButton/ActionIconButton";
import { LikeIcon, LikeOutlinedIcon } from "../../../icons";
import { likeTweet } from "../../../store/ducks/tweets/actionCreators";
import { useLikeIconButtonStyles } from "./LikeIconButtonStyles";
import { selectUserDataId } from '../../../store/ducks/user/selectors';
import { useGlobalStyles } from "../../../util/globalClasses";
import classNames from "classnames";

interface TweetLikeIconButtonProps {
    tweetId?: string;
    isTweetLiked?: boolean;
    likesCount?: number;
    isExplore?: boolean;
}

const LikeIconButton: FC<TweetLikeIconButtonProps> = memo(({ tweetId, isTweetLiked, likesCount, isExplore }): ReactElement => {
    const classes = useLikeIconButtonStyles({ isTweetLiked: isTweetLiked });
    const globalClasses = useGlobalStyles({});

    const [isLike, setIsLike] = React.useState(isTweetLiked);
    const [tweetLikeCount, setTweetLikeCount] = React.useState<number>(likesCount || 0);
    const [animationLike, setAnimationLike] = React.useState(globalClasses.countInit);
    
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);
    const params = useParams<{ userId: string }>();

    const handleLike = (): void => {
        setIsLike(!isLike);
        setTweetLikeCount((prevState) => isLike ? --prevState : ++prevState);
        setAnimationLike(isLike ? globalClasses.countGoDown : globalClasses.countGoUp);
        setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        dispatch(likeTweet({ tweetId: tweetId!, userId: params.userId ?? myProfileId, isExplore }));
    };

    // Update like counts on props changes
    React.useEffect(() => {
        if (likesCount !== undefined && likesCount !== tweetLikeCount) {
            setTweetLikeCount(likesCount);
            setAnimationLike(likesCount < tweetLikeCount ? globalClasses.countGoDown : globalClasses.countGoUp);
            setTimeout(() => setAnimationLike(globalClasses.countInit), 100); // Set 100 milliseconds time becasuse of the animation delay is 0.1s
        }
    }, [likesCount])

    return (
        <div className={classes.likeIcon}>
            <ActionIconButton
                actionText={isLike ? "Unlike" : "Like"}
                icon={isLike ? LikeIcon : LikeOutlinedIcon}
                onClick={handleLike}
            />
            {(tweetLikeCount !== 0) && (
                <span id={"likesCount"} className={classNames(classes.likesCount, animationLike)}>
                    {tweetLikeCount}
                </span>
            )}
        </div>
    );
});

export default LikeIconButton;
