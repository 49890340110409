import React, { FC, memo, ReactElement, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link as MuiLink, Typography } from "@material-ui/core";
import { selectUserProfileUsername } from "../../../store/ducks/userProfile/selectors";
import { useUserPageStyles } from "../UserPageStyles";

interface UserBlockedMessageProps {
    isMyProfileBlocked: boolean;
    isUserBlocked: boolean;
}

const UserBlockedMessage: FC<UserBlockedMessageProps> = memo(({ isMyProfileBlocked, isUserBlocked }): ReactElement => {
    const classes = useUserPageStyles();
    const username = useSelector(selectUserProfileUsername);

    const getText = useCallback((): string => {
        if (isMyProfileBlocked) {
            return `@${username} has blocked you`;
        }
        if (isUserBlocked) {
            return `@${username} is blocked`;
        }
        return `@${username} You’re blocked`;
    }, [isMyProfileBlocked, isUserBlocked, username]);

    return (
        <div className={classes.privateProfileInfo}>
            <Typography variant={"h4"} component={"div"}>
                {getText()}
            </Typography>
            <Typography variant={"subtitle1"} component={"div"}>
                {`You can’t follow or see @${username}’s Tweets.`}
                {/* <MuiLink variant="subtitle1" target="_blank" rel="noopener">
                    Learn more
                </MuiLink> */}
            </Typography>
        </div>
    );
});

export default UserBlockedMessage;
