import { FC, ReactElement } from "react";
import { withDocumentTitle } from "../../hoc/withDocumentTitle";
import { usePhoneRestrictionStyles } from "./PhoneRestrictionStyle";
import { Link } from "react-router-dom";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../constants/common-constants";

const PhoneRestriction: FC = (): ReactElement => {
    const classes = usePhoneRestrictionStyles();
    return (
        <div className={classes.container}>
            <h2 className={classes.description}>Please use mobile application for great user experience</h2>
            <div className={classes.downloadSec}>
                <Link
                    to={{
                        pathname: PLAY_STORE_LINK
                    }}
                    target="_blank"
                >
                    <img src={`play-store-logo.png`} width={250} height={`auto`} />
                </Link>
                <Link
                    to={{
                        pathname: APP_STORE_LINK
                    }}
                    target="_blank"
                >
                    <img src={`app-store-logo.webp`} width={250} height={`auto`} />
                </Link>
            </div>
        </div>
    );
};

export default withDocumentTitle(PhoneRestriction)("Restrictions");
