import { ListItem, Typography } from "@material-ui/core";
import { PaperPlaneIcon } from "../../../icons";
import React from "react";
import FullDeclinedTweetModal from "./FullDeclinedTweetModal/FullDeclinedTweetModal";

interface IProps {
    tweetId: string | number;
    appeal: any;
}

const FullDeclinedTweet = ({ tweetId, appeal }: IProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    
    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ListItem id={"appeal"} onClick={handleClick}>
                {PaperPlaneIcon}
                <Typography variant={"body1"} component={"span"}>
                    View appeal
                </Typography>
            </ListItem>
            <FullDeclinedTweetModal tweetId={tweetId} isOpen={isOpen} handleClose={handleClose} appeal={appeal} />
        </>
    );
};

export default FullDeclinedTweet;
