import produce, { Draft } from "immer";

import { ActionSnackbarActions, ActionSnackbarTypes } from "./contracts/actionTypes";
import { ActionSnackbarState, ActionSnackbarStateType } from "./contracts/state";

export const initialActionSnackbarState: ActionSnackbarState = {
    snackBarMessage: "",
    openSnackBar: false,
    type: ActionSnackbarStateType.SUCCESS
};

export const actionSnackbarReducer = produce((draft: Draft<ActionSnackbarState>, action: ActionSnackbarActions) => {
    switch (action.type) {
        case ActionSnackbarTypes.SET_OPEN_SNACKBAR:
            // if (draft.type) {
            //     console.log("DRAFT TYPE", action.payload);
            // } else {
            //     // console.log("DRAFT TYPE AUTO");
            //     // draft.type = ActionSnackbarStateType.SUCCESS;
            // }
            draft.type = action.payload.type;
            draft.snackBarMessage = action.payload.message;
            draft.openSnackBar = true;
            break;

        case ActionSnackbarTypes.SET_CLOSE_SNACKBAR:
            draft.openSnackBar = false;
            draft.type = ActionSnackbarStateType.SUCCESS
            break;

        default:
            break;
    }
}, initialActionSnackbarState);
