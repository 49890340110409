import { makeStyles } from "@material-ui/core";

export const useVideoTrimingStyles = makeStyles((theme) => ({
    dialog: {
        width: "100%",
        "& .MuiDialog-container": {
            maxHeight: "100%"
        }
    },
    dialogActions: {
        marginBottom: "0px"
    },
    content: {
        width: "100%",
        overflowX: "hidden",
        padding: 0,
        display: "flex",
        justifyContent: "center"
    },
    carouselContainer: {
        width: "100%",
        padding: "10px"
    },
    videoContainer: {
        padding: "8px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    trimButton: {
        borderRadius: "5px"
    },
    video: {
        width: "100%",
        height: "300px"
    },
    arrowPrev: {
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderRadius: "50%",
        outline: 0,
        border: 0,
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        position: "absolute",
        left: 10,
        zIndex: 1,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        "& svg": {
            color: theme.palette.common.white,
            height: "unset",
            width: "unset"
        }
    },
    arrowNext: {
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderRadius: "50%",
        outline: 0,
        border: 0,
        position: "absolute",
        backdropFilter: "blur(4px)",
        backgroundColor: "#000000bf",
        right: 10,
        zIndex: 1,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        "& svg": {
            color: theme.palette.common.white,
            height: "unset",
            width: "unset"
        }
    },
    slider: {
        "& .MuiSlider-valueLabel": {
            left: "calc(-50% - 0px)"
        },
        "& .PrivateValueLabel-offset-1353": {
            top: "-24px",
        },
        "& .PrivateValueLabel-circle-1354": {
            width: "24px",
            height: "24px"
        }
    }
}));
