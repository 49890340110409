import React, { ReactElement, useState } from "react";
import { Checkbox, Divider, Link as MuiLink, Switch, Typography } from "@material-ui/core";

import { usePersonalizationAndDataStyles } from "./PersonalizationAndDataStyles";
import { useGlobalStyles } from "../../../../util/globalClasses";
import { ArrowRightIcon } from "../../../../icons";
import { withDocumentTitle } from "../../../../hoc/withDocumentTitle";
import { APP_NAME } from '../../../../constants/common-constants';
// import {
//     ACROSS_YOUR_DEVICES,
//     DATA_THROUGH_PARTNERSHIPS,
//     PRIVACY_CONTROLS_FOR_TAILORED_ADS,
//     TAILORED_SUGGESTIONS
// } from "../../../../constants/url-constants";

const PersonalizationAndData = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = usePersonalizationAndDataStyles();
    const [checked1, setChecked1] = useState<boolean>(false);
    const [checked2, setChecked2] = useState<boolean>(true);
    const [checked3, setChecked3] = useState<boolean>(true);
    const [checked4, setChecked4] = useState<boolean>(true);
    const [checked5, setChecked5] = useState<boolean>(false);


    return (
        <div className={classes.content}>
            <Typography variant={"subtitle2"} component={"div"} className={globalClasses.itemInfoWrapper}>
                Control how {APP_NAME} personalizes content and collects and shares certain data.
            </Typography>
            <Divider />
            <div className={globalClasses.itemInfoWrapper}>
                <Typography variant={"body1"} component={"div"}>
                    Personalization and data
                    <span className={classes.switch}>
                        <Switch defaultChecked />
                    </span>
                </Typography>
                <Typography variant={"subtitle2"} component={"div"} className={classes.subText}>
                    This will enable or disable all of the settings on this page.
                </Typography>
            </div>
            <Divider />
            <div className={globalClasses.itemInfoWrapper}>
                <Typography variant={"h5"} component={"div"}>
                    Personalization
                </Typography>
            </div>
            <div className={globalClasses.itemInfoWrapper}>
                <div className={globalClasses.infoItemCheckbox}>
                    <Typography variant={"body1"} component={"span"}>
                        Personalized ads
                    </Typography>
                    <Checkbox checked={checked1} onChange={() => setChecked1(prevState => !prevState)} />
                </div>
                <Typography variant={"subtitle2"} component={"div"}>
                    {`You will always see ads on ${APP_NAME} based on your ${APP_NAME} activity. When this setting is enabled, 
                    ${APP_NAME} may further personalize ads from ${APP_NAME} advertisers, on and off ${APP_NAME}, by combining your 
                    {APP_NAME} activity with other online activity and information from our partners. `}
                    {/* <MuiLink variant="subtitle2" target="_blank" rel="noopener">
                        Learn more
                    </MuiLink> */}
                </Typography>
            </div>
            <div className={globalClasses.itemInfoWrapper}>
                <div className={globalClasses.infoItemCheckbox}>
                    <Typography variant={"body1"} component={"span"}>
                        Personalize based on your inferred identity
                    </Typography>
                    <Checkbox checked={checked2} onChange={() => setChecked2(prevState => !prevState)} />
                </div>
                <Typography variant={"subtitle2"} component={"div"}>
                    {`${APP_NAME} will always personalize your experience based on information you’ve provided, as well as 
                    the devices you’ve used to log in. When this setting is enabled, ${APP_NAME} may also personalize based 
                    on other inferences about your identity, like devices and browsers you haven’t used to log in to 
                    ${APP_NAME} or email addresses and phone numbers similar to those linked to your ${APP_NAME} account. `}
                    {/* <MuiLink variant="subtitle2" target="_blank" rel="noopener">
                        Learn more
                    </MuiLink> */}
                </Typography>
            </div>
            <div className={globalClasses.itemInfoWrapper}>
                <div className={globalClasses.infoItemCheckbox}>
                    <Typography variant={"body1"} component={"span"}>
                        Personalize based on the places you’ve been
                    </Typography>
                    <Checkbox checked={checked3} onChange={() => setChecked3(prevState => !prevState)} />
                </div>
                <Typography variant={"subtitle2"} component={"div"}>
                    {`${APP_NAME} always uses some information, like where you signed up and your current location, to help 
                    show you more relevant content. When this setting is enabled, ${APP_NAME} may also personalize your 
                    experience based on other places you’ve been. `}
                </Typography>
            </div>
            <Divider />
            <div className={globalClasses.itemInfoWrapper}>
                <Typography variant={"h5"} component={"div"}>
                    Data
                </Typography>
            </div>
            <div className={globalClasses.itemInfoWrapper}>
                <div className={globalClasses.infoItemCheckbox}>
                    <Typography variant={"body1"} component={"span"}>
                        Allow use of where you see {APP_NAME} content across the web
                    </Typography>
                    <Checkbox checked={checked4} onChange={() => setChecked4(prevState => !prevState)} />
                </div>
                <Typography variant={"subtitle2"} component={"div"}>
                    {`This setting lets ${APP_NAME} keep track of your visits to other websites that integrate ${APP_NAME} 
                    content, such as embedded timelines. That information makes ${APP_NAME} better for you, such as by 
                    personalizing your experience. This web browsing history will never be stored with your name, email, 
                    or phone number. `}
                    {/* <MuiLink variant="subtitle2" target="_blank" rel="noopener">
                        Learn more
                    </MuiLink> */}
                </Typography>
            </div>
            <div className={globalClasses.itemInfoWrapper}>
                <div className={globalClasses.infoItemCheckbox}>
                    <Typography variant={"body1"} component={"span"}>
                        Allow additional information sharing with business partners
                    </Typography>
                    <Checkbox checked={checked5} onChange={() => setChecked5(prevState => !prevState)} />
                </div>
                <Typography variant={"subtitle2"} component={"div"}>
                    {`${APP_NAME} always shares information with business partners as a way to run and improve its products. 
                    When enabled, this allows ${APP_NAME} to share additional information with those partners to help 
                    support running ${APP_NAME}’s business, including making ${APP_NAME}’s marketing activities on other sites 
                    and apps more relevant for you. `}
                    {/* <MuiLink variant="subtitle2" target="_blank" rel="noopener">
                        Learn more
                    </MuiLink> */}
                </Typography>
            </div>
            <div className={globalClasses.contentLink}>
                <Typography variant={"body1"} component={"span"}>
                    See your {APP_NAME} data
                </Typography>
                {ArrowRightIcon}
            </div>
        </div>
    );
};

export default withDocumentTitle(PersonalizationAndData)("Personalization and data");