import { FC, ReactElement, useEffect, useState } from "react";
import { Divider, Link as MuiLink, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useGlobalStyles } from "../../../../../util/globalClasses";
import { withDocumentTitle } from "../../../../../hoc/withDocumentTitle";
import { APP_NAME } from '../../../../../constants/common-constants';
import { selectUserProfileBirthday } from "../../../../../store/ducks/user/selectors";
import { calculateAge } from "../../../../../util/helper";
// import { BIRTHDAY_VISIBILITY_SETTINGS } from "../../../../../constants/url-constants";

const ChangeAge: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const birthday = useSelector(selectUserProfileBirthday);
    const [age, setAge] = useState("");

    useEffect(() => {
      if (birthday) {
        setAge(calculateAge(birthday));
      }
    }, [birthday])

    return (
        <div>
            <Typography variant={"body1"} component={"div"} className={globalClasses.itemInfoWrapper}>
                {`If you haven’t provided a date of birth, we’ve provided an age range based on your ${APP_NAME}
                    profile and activity. Age information is used to personalize your experience. `}
                {/* <MuiLink target="_blank" rel="noopener">
                    Learn more
                </MuiLink> */}
            </Typography>
            <Divider />
            <Typography variant={"body1"} component={"div"} className={globalClasses.itemInfoWrapper}>
                { age }
            </Typography>
            <Divider />
            <Typography variant={"body1"} component={"div"} className={globalClasses.itemInfoWrapper}>
                Not right? You can add your date of birth to your profile without sharing it publicly.
            </Typography>
        </div>
    );
};

export default withDocumentTitle(ChangeAge)("Age");
