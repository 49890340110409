import { Link as MuiLink } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { SEARCH } from '../../constants/path-constants';

const HashTagLink = ({ match }: { match: string }) => {
    return (
      <MuiLink 
        variant="subtitle1" 
        component={Link} 
        to={`${SEARCH}?tag=${encodeURIComponent(match)}`} 
        style={{ width: "max-content", textDecoration: 'none' }}
      >
        <b id="hashtag">{match}</b>
      </MuiLink>
    );
}

export default HashTagLink;