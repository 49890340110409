import { GifImageResponse, TweetResponse } from "../../../../types/tweet";
import { Image, LoadingStatus, ReplyType } from "../../../../types/common";

export interface TweetsState {
    items: TweetResponse[];
    deletedTweetsId: string[];
    mentionItems: TweetResponse[];
    exploreItems: TweetResponse[];
    newTweetCount: number;
    pagesCount: number;
    loadingState: LoadingStatus;
    tweetSentState: TweetSentState;
    tweetErrorMessage: string;
    declinedLoadingState?: LoadingStatus;
    declinedTweetsCount?: number;
    declinedTweets: any;
}


export interface TweetRequest {
    id?: string;
    text: string;
    images: Image[];
    imageDescription: string;
    taggedImageUsers: string[];
    replyType: ReplyType;
    pollDateTime?: number;
    scheduledDate?: Date;
    choices?: string[];
    listId?: string;
    gifImage?: GifImageResponse;
}

export interface AddQuoteTweetRequest {
    text: string;
    images: Image[];
    imageDescription: string;
    taggedImageUsers: string[];
    replyType: ReplyType;
    tweetId: string;
    userId?: string;
}

export interface VoteRequest {
    tweetId: string;
    pollId: string;
    pollChoiceId: string;
}

export interface TweetsByListIdRequest {
    listId: string,
    pageNumber: number
}

export interface TweetsWithQuotesByIdRequest {
    tweetId: string,
    pageNumber: number
}

export interface ChangeReplyTypeRequest {
    tweetId: string;
    userId?: string;
    replyType: ReplyType;
}

export interface UpdatedBookmarkedTweetPayload {
    tweetId: string;
    isTweetBookmarked: boolean;
}

export interface FetchTweetsByTextRequest {
    text: string;
    pageNumber: number;
}

export interface FetchTweetsByTagRequest {
    tag: string;
    pageNumber: number;
}

export interface TweetActionPayload {
    userId?: string;
    tweetId: string;
    isExplore?: boolean;
}

export enum TweetSentState {
    IDEL = "IDEL",
    SENT = "SENT",
    ERROR = "ERROR"
}

export interface FetchTweetsBySearchRequest {
    search?: string;
    pageNumber: number;
}

export interface TweetIdPayload {
    tweetId: string;
}

export interface DeclinedTweetPayload {
    userId?: string | number,
    pageNumber: number
}