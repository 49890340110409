import { makeStyles } from "@material-ui/core";

export const useDeclinedStyles = makeStyles((theme) => ({
    container: {
        "& .MuiTab-root": {
            textTransform: "none !important",
            minWidth: "150px !important",
            padding: "14px 12px !important"
        }
    },
}))