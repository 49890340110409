import React, { memo, ReactElement } from "react";
import { useSelector } from "react-redux";

import { usePopperListWindowStyles } from "../PopperListWindowStyles";
import { selectListDetailItemWallpaper } from "../../../../store/ducks/listDetail/selectors";
import { DEFAULT_LIST_IMG } from '../../../../constants/url-constants';

const PopperListWallpaper = memo((): ReactElement => {
    const classes = usePopperListWindowStyles();
    const wallpaper = useSelector(selectListDetailItemWallpaper);
    const listWallpaper = wallpaper ?? DEFAULT_LIST_IMG;

    return <img className={classes.wallpaperListImg} key={listWallpaper} src={listWallpaper} alt={listWallpaper} />;
});

export default PopperListWallpaper;
