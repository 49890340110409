import { Button, Dialog, DialogContent, TextareaAutosize, Typography } from "@material-ui/core";
import { useAppealModalStyles } from "./AppealModalStyles";
import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyAppealDeclinedTweet, fetchDeclinedTweets } from "../../../../store/ducks/tweets/actionCreators";
import { selectUserDataId } from "../../../../store/ducks/user/selectors";

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    tweetId: string | number;
}
const AppealModal = ({ isOpen, handleClose, tweetId }: IProps) => {
    const classes = useAppealModalStyles();
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);

    const [text, setText] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");

    const handleChangeText = React.useCallback((event: ChangeEvent<HTMLTextAreaElement>): void => {
        setText(event.target.value);
    }, []);

    const handleSubmit = () => {
        if (text.length) {
            setError("");
            dispatch(
                applyAppealDeclinedTweet({
                    contentId: String(tweetId),
                    userReason: text
                })
            );
            dispatch(fetchDeclinedTweets({ userId: myProfileId, pageNumber: 0 }));
            handleClose();
        } else {
            setError("Please a reason to apply a appeal");
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
            <DialogContent style={{ padding: 0 }}>
                <div className={classes.modalWrapper}>
                    <Typography variant={"h5"}>Apply For Appeal</Typography>
                    <TextareaAutosize
                        onChange={handleChangeText}
                        className={classes.contentTextarea}
                        placeholder={"Enter a detail..."}
                        value={text}
                        maxRows={18}
                        minRows={12}
                    />
                    {error && <div className={classes.errorAlign}>{error}</div>}
                    <div className={classes.modalButtonWrapper}>
                        <Button
                            className={classes.modalCancelButton}
                            onClick={handleClose}
                            variant="contained"
                            size="large"
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.modalPrimaryButton}
                            onClick={handleSubmit}
                            variant="contained"
                            size="large"
                        >
                            {`Save`}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AppealModal;
