import React, { FC, memo, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, ClickAwayListener, List } from "@material-ui/core";
import classnames from "classnames";

import { useDeclinedTweetActionsStyles } from "./DeclinedTweetActionsStyles";
import { EditIcon } from "../../../icons";
import { useGlobalStyles } from "../../../util/globalClasses";
import ActionIconButton from "../../ActionIconButton/ActionIconButton";
import ApplyForAppeal from "../ApplyForAppeal/ApplyForAppeal";
import FullDeclinedTweet from "../FullDeclinedTweet/FullDeclinedTweet";
import DeleteTweetButton from "../../TweetComponentActions/DeleteTweetButton/DeleteTweetButton";
import { selectUserDataId } from "../../../store/ducks/user/selectors";
import { fetchDeclinedTweets } from "../../../store/ducks/tweets/actionCreators";
import { useFullDeclinedTweetModalStyles } from "../FullDeclinedTweet/FullDeclinedTweetModal/FullDeclinedTweetModalStyles";

interface TweetComponentActionsProps {
    tweet: any;
    isFullTweet?: boolean;
    onOpenTweetAnalytics?: () => void;
}

const DeclinedTweetComponentActions: FC<TweetComponentActionsProps> = memo(({ tweet }): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useDeclinedTweetActionsStyles();
    const fullDeclinedClasses = useFullDeclinedTweetModalStyles();
    const myProfileId = useSelector(selectUserDataId);
    const dispatch = useDispatch();

    const appeal = React.useMemo(() => tweet?.appealResponse, [tweet]);

    const [openActionsDropdown, setOpenActionsDropdown] = useState<boolean>(false);
    const [openChangeReplyDropdown, setChangeReplyDropdown] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => document.removeEventListener("click", handleClickOutside, true);
    }, []);

    const handleClickOutside = (event: any): void => {
        if (ref.current && !ref.current.contains(event.target)) {
            setChangeReplyDropdown(false);
        }
    };

    const handleClickActionsDropdown = (): void => {
        setOpenActionsDropdown((prev) => !prev);
    };

    const handleClickAwayActionsDropdown = useCallback((): void => {
        setOpenActionsDropdown(false);
    }, []);

    const handleDelete = () => {
        handleClickAwayActionsDropdown();
        dispatch(fetchDeclinedTweets({ userId: myProfileId, pageNumber: 0 }));
    };

    const getChipClasses = (appealStatus: string) => {
        if (appealStatus === "PENDING") return fullDeclinedClasses.pendingChip;
        if (appealStatus === "APPROVE") return fullDeclinedClasses.approveChip;
        return fullDeclinedClasses.errorChip;
    }

    return (
        <div ref={ref}>
            <ClickAwayListener onClickAway={handleClickAwayActionsDropdown}>
                <div className={classes.root}>
                    {appeal && appeal?.status && (
                        <Chip
                            label={appeal?.status?.toLowerCase()}
                            size="small"
                            className={getChipClasses(appeal?.status)}
                            color="primary"
                            variant="default"
                        />
                    )}
                    <ActionIconButton actionText={"More"} onClick={handleClickActionsDropdown} icon={EditIcon} />
                    {openActionsDropdown && (
                        <div className={classnames(classes.dropdown, globalClasses.svg)}>
                            <List>
                                {!appeal && tweet?.challengeAllowed && (
                                    <ApplyForAppeal tweetId={tweet?.id} />
                                )}
                                {appeal && (
                                    <FullDeclinedTweet tweetId={tweet?.id} appeal={appeal} />
                                )}
                                <DeleteTweetButton tweetId={tweet.id} onCloseActionsDropdown={handleDelete} />
                            </List>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
});

export default DeclinedTweetComponentActions;
